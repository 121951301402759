@import '~purecss/build/base-min.css';
@import '~purecss/build/grids-core-min.css';
@import '~purecss/build/grids-min.css';
@import '~purecss/build/grids-responsive-min.css';
@import '~purecss/build/menus-min.css';
@import '~purecss/build/forms-min.css';
.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.inline-block {
  display: inline-block !important;
}

.hidden {
  display: none !important;
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.block-centered {
  margin-left: auto;
  margin-right: auto;
}

.ul-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.has-shadow {
  box-shadow: 0 0 10px 0 var(--color-shadow, rgba(0, 0, 0, 0.2));
}

.full-height {
  height: 100%;
}

.visible-sm,
.visible-md,
.visible-lg,
.visible-xl {
  display: none !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.ml-3 {
  margin-left: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mr-5 {
  margin-right: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.ml-5 {
  margin-left: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mr-6 {
  margin-right: 1.5rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.ml-6 {
  margin-left: 1.5rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.mr-7 {
  margin-right: 1.75rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.ml-7 {
  margin-left: 1.75rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mr-8 {
  margin-right: 2rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.ml-8 {
  margin-left: 2rem !important;
}

.mt-9 {
  margin-top: 2.25rem !important;
}

.mr-9 {
  margin-right: 2.25rem !important;
}

.mb-9 {
  margin-bottom: 2.25rem !important;
}

.ml-9 {
  margin-left: 2.25rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mr-10 {
  margin-right: 2.5rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.ml-10 {
  margin-left: 2.5rem !important;
}

.mt-11 {
  margin-top: 2.75rem !important;
}

.mr-11 {
  margin-right: 2.75rem !important;
}

.mb-11 {
  margin-bottom: 2.75rem !important;
}

.ml-11 {
  margin-left: 2.75rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mr-12 {
  margin-right: 3rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.ml-12 {
  margin-left: 3rem !important;
}

.mt-13 {
  margin-top: 3.25rem !important;
}

.mr-13 {
  margin-right: 3.25rem !important;
}

.mb-13 {
  margin-bottom: 3.25rem !important;
}

.ml-13 {
  margin-left: 3.25rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mr-14 {
  margin-right: 3.5rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.ml-14 {
  margin-left: 3.5rem !important;
}

.mt-15 {
  margin-top: 3.75rem !important;
}

.mr-15 {
  margin-right: 3.75rem !important;
}

.mb-15 {
  margin-bottom: 3.75rem !important;
}

.ml-15 {
  margin-left: 3.75rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mr-16 {
  margin-right: 4rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.ml-16 {
  margin-left: 4rem !important;
}

.mt-17 {
  margin-top: 4.25rem !important;
}

.mr-17 {
  margin-right: 4.25rem !important;
}

.mb-17 {
  margin-bottom: 4.25rem !important;
}

.ml-17 {
  margin-left: 4.25rem !important;
}

.mt-18 {
  margin-top: 4.5rem !important;
}

.mr-18 {
  margin-right: 4.5rem !important;
}

.mb-18 {
  margin-bottom: 4.5rem !important;
}

.ml-18 {
  margin-left: 4.5rem !important;
}

.mt-19 {
  margin-top: 4.75rem !important;
}

.mr-19 {
  margin-right: 4.75rem !important;
}

.mb-19 {
  margin-bottom: 4.75rem !important;
}

.ml-19 {
  margin-left: 4.75rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mr-20 {
  margin-right: 5rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.ml-20 {
  margin-left: 5rem !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pr-3 {
  padding-right: 0.75rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pl-3 {
  padding-left: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pr-5 {
  padding-right: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pl-5 {
  padding-left: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pr-6 {
  padding-right: 1.5rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pl-6 {
  padding-left: 1.5rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.pr-7 {
  padding-right: 1.75rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.pl-7 {
  padding-left: 1.75rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pr-8 {
  padding-right: 2rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pl-8 {
  padding-left: 2rem !important;
}

.pt-9 {
  padding-top: 2.25rem !important;
}

.pr-9 {
  padding-right: 2.25rem !important;
}

.pb-9 {
  padding-bottom: 2.25rem !important;
}

.pl-9 {
  padding-left: 2.25rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pr-10 {
  padding-right: 2.5rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pl-10 {
  padding-left: 2.5rem !important;
}

.pt-11 {
  padding-top: 2.75rem !important;
}

.pr-11 {
  padding-right: 2.75rem !important;
}

.pb-11 {
  padding-bottom: 2.75rem !important;
}

.pl-11 {
  padding-left: 2.75rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pr-12 {
  padding-right: 3rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pl-12 {
  padding-left: 3rem !important;
}

.pt-13 {
  padding-top: 3.25rem !important;
}

.pr-13 {
  padding-right: 3.25rem !important;
}

.pb-13 {
  padding-bottom: 3.25rem !important;
}

.pl-13 {
  padding-left: 3.25rem !important;
}

.pt-14 {
  padding-top: 3.5rem !important;
}

.pr-14 {
  padding-right: 3.5rem !important;
}

.pb-14 {
  padding-bottom: 3.5rem !important;
}

.pl-14 {
  padding-left: 3.5rem !important;
}

.pt-15 {
  padding-top: 3.75rem !important;
}

.pr-15 {
  padding-right: 3.75rem !important;
}

.pb-15 {
  padding-bottom: 3.75rem !important;
}

.pl-15 {
  padding-left: 3.75rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pr-16 {
  padding-right: 4rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pl-16 {
  padding-left: 4rem !important;
}

.pt-17 {
  padding-top: 4.25rem !important;
}

.pr-17 {
  padding-right: 4.25rem !important;
}

.pb-17 {
  padding-bottom: 4.25rem !important;
}

.pl-17 {
  padding-left: 4.25rem !important;
}

.pt-18 {
  padding-top: 4.5rem !important;
}

.pr-18 {
  padding-right: 4.5rem !important;
}

.pb-18 {
  padding-bottom: 4.5rem !important;
}

.pl-18 {
  padding-left: 4.5rem !important;
}

.pt-19 {
  padding-top: 4.75rem !important;
}

.pr-19 {
  padding-right: 4.75rem !important;
}

.pb-19 {
  padding-bottom: 4.75rem !important;
}

.pl-19 {
  padding-left: 4.75rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pr-20 {
  padding-right: 5rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.pl-20 {
  padding-left: 5rem !important;
}

.font-xxs {
  font-size: 0.625rem !important;
  line-height: 1rem !important;
}

.font-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.font-sm {
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
}

.font-normal {
  font-size: 1rem !important;
  line-height: 1.5 !important;
}

.font-lg {
  font-size: 1.125rem !important;
  line-height: 1.5 !important;
}

@media screen and (min-width: 35.5em) {
  .sm-mt-0 {
    margin-top: 0rem !important;
  }
  .sm-mr-0 {
    margin-right: 0rem !important;
  }
  .sm-mb-0 {
    margin-bottom: 0rem !important;
  }
  .sm-ml-0 {
    margin-left: 0rem !important;
  }
  .sm-mt-1 {
    margin-top: 0.25rem !important;
  }
  .sm-mr-1 {
    margin-right: 0.25rem !important;
  }
  .sm-mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .sm-ml-1 {
    margin-left: 0.25rem !important;
  }
  .sm-mt-2 {
    margin-top: 0.5rem !important;
  }
  .sm-mr-2 {
    margin-right: 0.5rem !important;
  }
  .sm-mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .sm-ml-2 {
    margin-left: 0.5rem !important;
  }
  .sm-mt-3 {
    margin-top: 0.75rem !important;
  }
  .sm-mr-3 {
    margin-right: 0.75rem !important;
  }
  .sm-mb-3 {
    margin-bottom: 0.75rem !important;
  }
  .sm-ml-3 {
    margin-left: 0.75rem !important;
  }
  .sm-mt-4 {
    margin-top: 1rem !important;
  }
  .sm-mr-4 {
    margin-right: 1rem !important;
  }
  .sm-mb-4 {
    margin-bottom: 1rem !important;
  }
  .sm-ml-4 {
    margin-left: 1rem !important;
  }
  .sm-mt-5 {
    margin-top: 1.25rem !important;
  }
  .sm-mr-5 {
    margin-right: 1.25rem !important;
  }
  .sm-mb-5 {
    margin-bottom: 1.25rem !important;
  }
  .sm-ml-5 {
    margin-left: 1.25rem !important;
  }
  .sm-mt-6 {
    margin-top: 1.5rem !important;
  }
  .sm-mr-6 {
    margin-right: 1.5rem !important;
  }
  .sm-mb-6 {
    margin-bottom: 1.5rem !important;
  }
  .sm-ml-6 {
    margin-left: 1.5rem !important;
  }
  .sm-mt-7 {
    margin-top: 1.75rem !important;
  }
  .sm-mr-7 {
    margin-right: 1.75rem !important;
  }
  .sm-mb-7 {
    margin-bottom: 1.75rem !important;
  }
  .sm-ml-7 {
    margin-left: 1.75rem !important;
  }
  .sm-mt-8 {
    margin-top: 2rem !important;
  }
  .sm-mr-8 {
    margin-right: 2rem !important;
  }
  .sm-mb-8 {
    margin-bottom: 2rem !important;
  }
  .sm-ml-8 {
    margin-left: 2rem !important;
  }
  .sm-mt-9 {
    margin-top: 2.25rem !important;
  }
  .sm-mr-9 {
    margin-right: 2.25rem !important;
  }
  .sm-mb-9 {
    margin-bottom: 2.25rem !important;
  }
  .sm-ml-9 {
    margin-left: 2.25rem !important;
  }
  .sm-mt-10 {
    margin-top: 2.5rem !important;
  }
  .sm-mr-10 {
    margin-right: 2.5rem !important;
  }
  .sm-mb-10 {
    margin-bottom: 2.5rem !important;
  }
  .sm-ml-10 {
    margin-left: 2.5rem !important;
  }
  .sm-mt-11 {
    margin-top: 2.75rem !important;
  }
  .sm-mr-11 {
    margin-right: 2.75rem !important;
  }
  .sm-mb-11 {
    margin-bottom: 2.75rem !important;
  }
  .sm-ml-11 {
    margin-left: 2.75rem !important;
  }
  .sm-mt-12 {
    margin-top: 3rem !important;
  }
  .sm-mr-12 {
    margin-right: 3rem !important;
  }
  .sm-mb-12 {
    margin-bottom: 3rem !important;
  }
  .sm-ml-12 {
    margin-left: 3rem !important;
  }
  .sm-mt-13 {
    margin-top: 3.25rem !important;
  }
  .sm-mr-13 {
    margin-right: 3.25rem !important;
  }
  .sm-mb-13 {
    margin-bottom: 3.25rem !important;
  }
  .sm-ml-13 {
    margin-left: 3.25rem !important;
  }
  .sm-mt-14 {
    margin-top: 3.5rem !important;
  }
  .sm-mr-14 {
    margin-right: 3.5rem !important;
  }
  .sm-mb-14 {
    margin-bottom: 3.5rem !important;
  }
  .sm-ml-14 {
    margin-left: 3.5rem !important;
  }
  .sm-mt-15 {
    margin-top: 3.75rem !important;
  }
  .sm-mr-15 {
    margin-right: 3.75rem !important;
  }
  .sm-mb-15 {
    margin-bottom: 3.75rem !important;
  }
  .sm-ml-15 {
    margin-left: 3.75rem !important;
  }
  .sm-mt-16 {
    margin-top: 4rem !important;
  }
  .sm-mr-16 {
    margin-right: 4rem !important;
  }
  .sm-mb-16 {
    margin-bottom: 4rem !important;
  }
  .sm-ml-16 {
    margin-left: 4rem !important;
  }
  .sm-mt-17 {
    margin-top: 4.25rem !important;
  }
  .sm-mr-17 {
    margin-right: 4.25rem !important;
  }
  .sm-mb-17 {
    margin-bottom: 4.25rem !important;
  }
  .sm-ml-17 {
    margin-left: 4.25rem !important;
  }
  .sm-mt-18 {
    margin-top: 4.5rem !important;
  }
  .sm-mr-18 {
    margin-right: 4.5rem !important;
  }
  .sm-mb-18 {
    margin-bottom: 4.5rem !important;
  }
  .sm-ml-18 {
    margin-left: 4.5rem !important;
  }
  .sm-mt-19 {
    margin-top: 4.75rem !important;
  }
  .sm-mr-19 {
    margin-right: 4.75rem !important;
  }
  .sm-mb-19 {
    margin-bottom: 4.75rem !important;
  }
  .sm-ml-19 {
    margin-left: 4.75rem !important;
  }
  .sm-mt-20 {
    margin-top: 5rem !important;
  }
  .sm-mr-20 {
    margin-right: 5rem !important;
  }
  .sm-mb-20 {
    margin-bottom: 5rem !important;
  }
  .sm-ml-20 {
    margin-left: 5rem !important;
  }
  .sm-pt-0 {
    padding-top: 0rem !important;
  }
  .sm-pr-0 {
    padding-right: 0rem !important;
  }
  .sm-pb-0 {
    padding-bottom: 0rem !important;
  }
  .sm-pl-0 {
    padding-left: 0rem !important;
  }
  .sm-pt-1 {
    padding-top: 0.25rem !important;
  }
  .sm-pr-1 {
    padding-right: 0.25rem !important;
  }
  .sm-pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .sm-pl-1 {
    padding-left: 0.25rem !important;
  }
  .sm-pt-2 {
    padding-top: 0.5rem !important;
  }
  .sm-pr-2 {
    padding-right: 0.5rem !important;
  }
  .sm-pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .sm-pl-2 {
    padding-left: 0.5rem !important;
  }
  .sm-pt-3 {
    padding-top: 0.75rem !important;
  }
  .sm-pr-3 {
    padding-right: 0.75rem !important;
  }
  .sm-pb-3 {
    padding-bottom: 0.75rem !important;
  }
  .sm-pl-3 {
    padding-left: 0.75rem !important;
  }
  .sm-pt-4 {
    padding-top: 1rem !important;
  }
  .sm-pr-4 {
    padding-right: 1rem !important;
  }
  .sm-pb-4 {
    padding-bottom: 1rem !important;
  }
  .sm-pl-4 {
    padding-left: 1rem !important;
  }
  .sm-pt-5 {
    padding-top: 1.25rem !important;
  }
  .sm-pr-5 {
    padding-right: 1.25rem !important;
  }
  .sm-pb-5 {
    padding-bottom: 1.25rem !important;
  }
  .sm-pl-5 {
    padding-left: 1.25rem !important;
  }
  .sm-pt-6 {
    padding-top: 1.5rem !important;
  }
  .sm-pr-6 {
    padding-right: 1.5rem !important;
  }
  .sm-pb-6 {
    padding-bottom: 1.5rem !important;
  }
  .sm-pl-6 {
    padding-left: 1.5rem !important;
  }
  .sm-pt-7 {
    padding-top: 1.75rem !important;
  }
  .sm-pr-7 {
    padding-right: 1.75rem !important;
  }
  .sm-pb-7 {
    padding-bottom: 1.75rem !important;
  }
  .sm-pl-7 {
    padding-left: 1.75rem !important;
  }
  .sm-pt-8 {
    padding-top: 2rem !important;
  }
  .sm-pr-8 {
    padding-right: 2rem !important;
  }
  .sm-pb-8 {
    padding-bottom: 2rem !important;
  }
  .sm-pl-8 {
    padding-left: 2rem !important;
  }
  .sm-pt-9 {
    padding-top: 2.25rem !important;
  }
  .sm-pr-9 {
    padding-right: 2.25rem !important;
  }
  .sm-pb-9 {
    padding-bottom: 2.25rem !important;
  }
  .sm-pl-9 {
    padding-left: 2.25rem !important;
  }
  .sm-pt-10 {
    padding-top: 2.5rem !important;
  }
  .sm-pr-10 {
    padding-right: 2.5rem !important;
  }
  .sm-pb-10 {
    padding-bottom: 2.5rem !important;
  }
  .sm-pl-10 {
    padding-left: 2.5rem !important;
  }
  .sm-pt-11 {
    padding-top: 2.75rem !important;
  }
  .sm-pr-11 {
    padding-right: 2.75rem !important;
  }
  .sm-pb-11 {
    padding-bottom: 2.75rem !important;
  }
  .sm-pl-11 {
    padding-left: 2.75rem !important;
  }
  .sm-pt-12 {
    padding-top: 3rem !important;
  }
  .sm-pr-12 {
    padding-right: 3rem !important;
  }
  .sm-pb-12 {
    padding-bottom: 3rem !important;
  }
  .sm-pl-12 {
    padding-left: 3rem !important;
  }
  .sm-pt-13 {
    padding-top: 3.25rem !important;
  }
  .sm-pr-13 {
    padding-right: 3.25rem !important;
  }
  .sm-pb-13 {
    padding-bottom: 3.25rem !important;
  }
  .sm-pl-13 {
    padding-left: 3.25rem !important;
  }
  .sm-pt-14 {
    padding-top: 3.5rem !important;
  }
  .sm-pr-14 {
    padding-right: 3.5rem !important;
  }
  .sm-pb-14 {
    padding-bottom: 3.5rem !important;
  }
  .sm-pl-14 {
    padding-left: 3.5rem !important;
  }
  .sm-pt-15 {
    padding-top: 3.75rem !important;
  }
  .sm-pr-15 {
    padding-right: 3.75rem !important;
  }
  .sm-pb-15 {
    padding-bottom: 3.75rem !important;
  }
  .sm-pl-15 {
    padding-left: 3.75rem !important;
  }
  .sm-pt-16 {
    padding-top: 4rem !important;
  }
  .sm-pr-16 {
    padding-right: 4rem !important;
  }
  .sm-pb-16 {
    padding-bottom: 4rem !important;
  }
  .sm-pl-16 {
    padding-left: 4rem !important;
  }
  .sm-pt-17 {
    padding-top: 4.25rem !important;
  }
  .sm-pr-17 {
    padding-right: 4.25rem !important;
  }
  .sm-pb-17 {
    padding-bottom: 4.25rem !important;
  }
  .sm-pl-17 {
    padding-left: 4.25rem !important;
  }
  .sm-pt-18 {
    padding-top: 4.5rem !important;
  }
  .sm-pr-18 {
    padding-right: 4.5rem !important;
  }
  .sm-pb-18 {
    padding-bottom: 4.5rem !important;
  }
  .sm-pl-18 {
    padding-left: 4.5rem !important;
  }
  .sm-pt-19 {
    padding-top: 4.75rem !important;
  }
  .sm-pr-19 {
    padding-right: 4.75rem !important;
  }
  .sm-pb-19 {
    padding-bottom: 4.75rem !important;
  }
  .sm-pl-19 {
    padding-left: 4.75rem !important;
  }
  .sm-pt-20 {
    padding-top: 5rem !important;
  }
  .sm-pr-20 {
    padding-right: 5rem !important;
  }
  .sm-pb-20 {
    padding-bottom: 5rem !important;
  }
  .sm-pl-20 {
    padding-left: 5rem !important;
  }
  .sm-font-xxs {
    font-size: 0.625rem !important;
    line-height: 1rem !important;
  }
  .sm-font-xs {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }
  .sm-font-sm {
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
  }
  .sm-font-normal {
    font-size: 1rem !important;
    line-height: 1.5 !important;
  }
  .sm-font-lg {
    font-size: 1.125rem !important;
    line-height: 1.5 !important;
  }
  .text-right-sm {
    text-align: right;
  }
  .hidden-sm {
    display: none !important;
  }
  .visible-sm {
    display: block !important;
  }
  .visible-sm[data-flex] {
    display: flex !important;
  }
}
@media screen and (min-width: 48em) {
  .md-mt-0 {
    margin-top: 0rem !important;
  }
  .md-mr-0 {
    margin-right: 0rem !important;
  }
  .md-mb-0 {
    margin-bottom: 0rem !important;
  }
  .md-ml-0 {
    margin-left: 0rem !important;
  }
  .md-mt-1 {
    margin-top: 0.25rem !important;
  }
  .md-mr-1 {
    margin-right: 0.25rem !important;
  }
  .md-mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .md-ml-1 {
    margin-left: 0.25rem !important;
  }
  .md-mt-2 {
    margin-top: 0.5rem !important;
  }
  .md-mr-2 {
    margin-right: 0.5rem !important;
  }
  .md-mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .md-ml-2 {
    margin-left: 0.5rem !important;
  }
  .md-mt-3 {
    margin-top: 0.75rem !important;
  }
  .md-mr-3 {
    margin-right: 0.75rem !important;
  }
  .md-mb-3 {
    margin-bottom: 0.75rem !important;
  }
  .md-ml-3 {
    margin-left: 0.75rem !important;
  }
  .md-mt-4 {
    margin-top: 1rem !important;
  }
  .md-mr-4 {
    margin-right: 1rem !important;
  }
  .md-mb-4 {
    margin-bottom: 1rem !important;
  }
  .md-ml-4 {
    margin-left: 1rem !important;
  }
  .md-mt-5 {
    margin-top: 1.25rem !important;
  }
  .md-mr-5 {
    margin-right: 1.25rem !important;
  }
  .md-mb-5 {
    margin-bottom: 1.25rem !important;
  }
  .md-ml-5 {
    margin-left: 1.25rem !important;
  }
  .md-mt-6 {
    margin-top: 1.5rem !important;
  }
  .md-mr-6 {
    margin-right: 1.5rem !important;
  }
  .md-mb-6 {
    margin-bottom: 1.5rem !important;
  }
  .md-ml-6 {
    margin-left: 1.5rem !important;
  }
  .md-mt-7 {
    margin-top: 1.75rem !important;
  }
  .md-mr-7 {
    margin-right: 1.75rem !important;
  }
  .md-mb-7 {
    margin-bottom: 1.75rem !important;
  }
  .md-ml-7 {
    margin-left: 1.75rem !important;
  }
  .md-mt-8 {
    margin-top: 2rem !important;
  }
  .md-mr-8 {
    margin-right: 2rem !important;
  }
  .md-mb-8 {
    margin-bottom: 2rem !important;
  }
  .md-ml-8 {
    margin-left: 2rem !important;
  }
  .md-mt-9 {
    margin-top: 2.25rem !important;
  }
  .md-mr-9 {
    margin-right: 2.25rem !important;
  }
  .md-mb-9 {
    margin-bottom: 2.25rem !important;
  }
  .md-ml-9 {
    margin-left: 2.25rem !important;
  }
  .md-mt-10 {
    margin-top: 2.5rem !important;
  }
  .md-mr-10 {
    margin-right: 2.5rem !important;
  }
  .md-mb-10 {
    margin-bottom: 2.5rem !important;
  }
  .md-ml-10 {
    margin-left: 2.5rem !important;
  }
  .md-mt-11 {
    margin-top: 2.75rem !important;
  }
  .md-mr-11 {
    margin-right: 2.75rem !important;
  }
  .md-mb-11 {
    margin-bottom: 2.75rem !important;
  }
  .md-ml-11 {
    margin-left: 2.75rem !important;
  }
  .md-mt-12 {
    margin-top: 3rem !important;
  }
  .md-mr-12 {
    margin-right: 3rem !important;
  }
  .md-mb-12 {
    margin-bottom: 3rem !important;
  }
  .md-ml-12 {
    margin-left: 3rem !important;
  }
  .md-mt-13 {
    margin-top: 3.25rem !important;
  }
  .md-mr-13 {
    margin-right: 3.25rem !important;
  }
  .md-mb-13 {
    margin-bottom: 3.25rem !important;
  }
  .md-ml-13 {
    margin-left: 3.25rem !important;
  }
  .md-mt-14 {
    margin-top: 3.5rem !important;
  }
  .md-mr-14 {
    margin-right: 3.5rem !important;
  }
  .md-mb-14 {
    margin-bottom: 3.5rem !important;
  }
  .md-ml-14 {
    margin-left: 3.5rem !important;
  }
  .md-mt-15 {
    margin-top: 3.75rem !important;
  }
  .md-mr-15 {
    margin-right: 3.75rem !important;
  }
  .md-mb-15 {
    margin-bottom: 3.75rem !important;
  }
  .md-ml-15 {
    margin-left: 3.75rem !important;
  }
  .md-mt-16 {
    margin-top: 4rem !important;
  }
  .md-mr-16 {
    margin-right: 4rem !important;
  }
  .md-mb-16 {
    margin-bottom: 4rem !important;
  }
  .md-ml-16 {
    margin-left: 4rem !important;
  }
  .md-mt-17 {
    margin-top: 4.25rem !important;
  }
  .md-mr-17 {
    margin-right: 4.25rem !important;
  }
  .md-mb-17 {
    margin-bottom: 4.25rem !important;
  }
  .md-ml-17 {
    margin-left: 4.25rem !important;
  }
  .md-mt-18 {
    margin-top: 4.5rem !important;
  }
  .md-mr-18 {
    margin-right: 4.5rem !important;
  }
  .md-mb-18 {
    margin-bottom: 4.5rem !important;
  }
  .md-ml-18 {
    margin-left: 4.5rem !important;
  }
  .md-mt-19 {
    margin-top: 4.75rem !important;
  }
  .md-mr-19 {
    margin-right: 4.75rem !important;
  }
  .md-mb-19 {
    margin-bottom: 4.75rem !important;
  }
  .md-ml-19 {
    margin-left: 4.75rem !important;
  }
  .md-mt-20 {
    margin-top: 5rem !important;
  }
  .md-mr-20 {
    margin-right: 5rem !important;
  }
  .md-mb-20 {
    margin-bottom: 5rem !important;
  }
  .md-ml-20 {
    margin-left: 5rem !important;
  }
  .md-pt-0 {
    padding-top: 0rem !important;
  }
  .md-pr-0 {
    padding-right: 0rem !important;
  }
  .md-pb-0 {
    padding-bottom: 0rem !important;
  }
  .md-pl-0 {
    padding-left: 0rem !important;
  }
  .md-pt-1 {
    padding-top: 0.25rem !important;
  }
  .md-pr-1 {
    padding-right: 0.25rem !important;
  }
  .md-pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .md-pl-1 {
    padding-left: 0.25rem !important;
  }
  .md-pt-2 {
    padding-top: 0.5rem !important;
  }
  .md-pr-2 {
    padding-right: 0.5rem !important;
  }
  .md-pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .md-pl-2 {
    padding-left: 0.5rem !important;
  }
  .md-pt-3 {
    padding-top: 0.75rem !important;
  }
  .md-pr-3 {
    padding-right: 0.75rem !important;
  }
  .md-pb-3 {
    padding-bottom: 0.75rem !important;
  }
  .md-pl-3 {
    padding-left: 0.75rem !important;
  }
  .md-pt-4 {
    padding-top: 1rem !important;
  }
  .md-pr-4 {
    padding-right: 1rem !important;
  }
  .md-pb-4 {
    padding-bottom: 1rem !important;
  }
  .md-pl-4 {
    padding-left: 1rem !important;
  }
  .md-pt-5 {
    padding-top: 1.25rem !important;
  }
  .md-pr-5 {
    padding-right: 1.25rem !important;
  }
  .md-pb-5 {
    padding-bottom: 1.25rem !important;
  }
  .md-pl-5 {
    padding-left: 1.25rem !important;
  }
  .md-pt-6 {
    padding-top: 1.5rem !important;
  }
  .md-pr-6 {
    padding-right: 1.5rem !important;
  }
  .md-pb-6 {
    padding-bottom: 1.5rem !important;
  }
  .md-pl-6 {
    padding-left: 1.5rem !important;
  }
  .md-pt-7 {
    padding-top: 1.75rem !important;
  }
  .md-pr-7 {
    padding-right: 1.75rem !important;
  }
  .md-pb-7 {
    padding-bottom: 1.75rem !important;
  }
  .md-pl-7 {
    padding-left: 1.75rem !important;
  }
  .md-pt-8 {
    padding-top: 2rem !important;
  }
  .md-pr-8 {
    padding-right: 2rem !important;
  }
  .md-pb-8 {
    padding-bottom: 2rem !important;
  }
  .md-pl-8 {
    padding-left: 2rem !important;
  }
  .md-pt-9 {
    padding-top: 2.25rem !important;
  }
  .md-pr-9 {
    padding-right: 2.25rem !important;
  }
  .md-pb-9 {
    padding-bottom: 2.25rem !important;
  }
  .md-pl-9 {
    padding-left: 2.25rem !important;
  }
  .md-pt-10 {
    padding-top: 2.5rem !important;
  }
  .md-pr-10 {
    padding-right: 2.5rem !important;
  }
  .md-pb-10 {
    padding-bottom: 2.5rem !important;
  }
  .md-pl-10 {
    padding-left: 2.5rem !important;
  }
  .md-pt-11 {
    padding-top: 2.75rem !important;
  }
  .md-pr-11 {
    padding-right: 2.75rem !important;
  }
  .md-pb-11 {
    padding-bottom: 2.75rem !important;
  }
  .md-pl-11 {
    padding-left: 2.75rem !important;
  }
  .md-pt-12 {
    padding-top: 3rem !important;
  }
  .md-pr-12 {
    padding-right: 3rem !important;
  }
  .md-pb-12 {
    padding-bottom: 3rem !important;
  }
  .md-pl-12 {
    padding-left: 3rem !important;
  }
  .md-pt-13 {
    padding-top: 3.25rem !important;
  }
  .md-pr-13 {
    padding-right: 3.25rem !important;
  }
  .md-pb-13 {
    padding-bottom: 3.25rem !important;
  }
  .md-pl-13 {
    padding-left: 3.25rem !important;
  }
  .md-pt-14 {
    padding-top: 3.5rem !important;
  }
  .md-pr-14 {
    padding-right: 3.5rem !important;
  }
  .md-pb-14 {
    padding-bottom: 3.5rem !important;
  }
  .md-pl-14 {
    padding-left: 3.5rem !important;
  }
  .md-pt-15 {
    padding-top: 3.75rem !important;
  }
  .md-pr-15 {
    padding-right: 3.75rem !important;
  }
  .md-pb-15 {
    padding-bottom: 3.75rem !important;
  }
  .md-pl-15 {
    padding-left: 3.75rem !important;
  }
  .md-pt-16 {
    padding-top: 4rem !important;
  }
  .md-pr-16 {
    padding-right: 4rem !important;
  }
  .md-pb-16 {
    padding-bottom: 4rem !important;
  }
  .md-pl-16 {
    padding-left: 4rem !important;
  }
  .md-pt-17 {
    padding-top: 4.25rem !important;
  }
  .md-pr-17 {
    padding-right: 4.25rem !important;
  }
  .md-pb-17 {
    padding-bottom: 4.25rem !important;
  }
  .md-pl-17 {
    padding-left: 4.25rem !important;
  }
  .md-pt-18 {
    padding-top: 4.5rem !important;
  }
  .md-pr-18 {
    padding-right: 4.5rem !important;
  }
  .md-pb-18 {
    padding-bottom: 4.5rem !important;
  }
  .md-pl-18 {
    padding-left: 4.5rem !important;
  }
  .md-pt-19 {
    padding-top: 4.75rem !important;
  }
  .md-pr-19 {
    padding-right: 4.75rem !important;
  }
  .md-pb-19 {
    padding-bottom: 4.75rem !important;
  }
  .md-pl-19 {
    padding-left: 4.75rem !important;
  }
  .md-pt-20 {
    padding-top: 5rem !important;
  }
  .md-pr-20 {
    padding-right: 5rem !important;
  }
  .md-pb-20 {
    padding-bottom: 5rem !important;
  }
  .md-pl-20 {
    padding-left: 5rem !important;
  }
  .md-font-xxs {
    font-size: 0.625rem !important;
    line-height: 1rem !important;
  }
  .md-font-xs {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }
  .md-font-sm {
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
  }
  .md-font-normal {
    font-size: 1rem !important;
    line-height: 1.5 !important;
  }
  .md-font-lg {
    font-size: 1.125rem !important;
    line-height: 1.5 !important;
  }
  .text-right-md {
    text-align: right;
  }
  .hidden-md {
    display: none !important;
  }
  .visible-md {
    display: block !important;
  }
  .visible-md[data-flex] {
    display: flex !important;
  }
}
@media screen and (min-width: 64em) {
  .lg-mt-0 {
    margin-top: 0rem !important;
  }
  .lg-mr-0 {
    margin-right: 0rem !important;
  }
  .lg-mb-0 {
    margin-bottom: 0rem !important;
  }
  .lg-ml-0 {
    margin-left: 0rem !important;
  }
  .lg-mt-1 {
    margin-top: 0.25rem !important;
  }
  .lg-mr-1 {
    margin-right: 0.25rem !important;
  }
  .lg-mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .lg-ml-1 {
    margin-left: 0.25rem !important;
  }
  .lg-mt-2 {
    margin-top: 0.5rem !important;
  }
  .lg-mr-2 {
    margin-right: 0.5rem !important;
  }
  .lg-mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .lg-ml-2 {
    margin-left: 0.5rem !important;
  }
  .lg-mt-3 {
    margin-top: 0.75rem !important;
  }
  .lg-mr-3 {
    margin-right: 0.75rem !important;
  }
  .lg-mb-3 {
    margin-bottom: 0.75rem !important;
  }
  .lg-ml-3 {
    margin-left: 0.75rem !important;
  }
  .lg-mt-4 {
    margin-top: 1rem !important;
  }
  .lg-mr-4 {
    margin-right: 1rem !important;
  }
  .lg-mb-4 {
    margin-bottom: 1rem !important;
  }
  .lg-ml-4 {
    margin-left: 1rem !important;
  }
  .lg-mt-5 {
    margin-top: 1.25rem !important;
  }
  .lg-mr-5 {
    margin-right: 1.25rem !important;
  }
  .lg-mb-5 {
    margin-bottom: 1.25rem !important;
  }
  .lg-ml-5 {
    margin-left: 1.25rem !important;
  }
  .lg-mt-6 {
    margin-top: 1.5rem !important;
  }
  .lg-mr-6 {
    margin-right: 1.5rem !important;
  }
  .lg-mb-6 {
    margin-bottom: 1.5rem !important;
  }
  .lg-ml-6 {
    margin-left: 1.5rem !important;
  }
  .lg-mt-7 {
    margin-top: 1.75rem !important;
  }
  .lg-mr-7 {
    margin-right: 1.75rem !important;
  }
  .lg-mb-7 {
    margin-bottom: 1.75rem !important;
  }
  .lg-ml-7 {
    margin-left: 1.75rem !important;
  }
  .lg-mt-8 {
    margin-top: 2rem !important;
  }
  .lg-mr-8 {
    margin-right: 2rem !important;
  }
  .lg-mb-8 {
    margin-bottom: 2rem !important;
  }
  .lg-ml-8 {
    margin-left: 2rem !important;
  }
  .lg-mt-9 {
    margin-top: 2.25rem !important;
  }
  .lg-mr-9 {
    margin-right: 2.25rem !important;
  }
  .lg-mb-9 {
    margin-bottom: 2.25rem !important;
  }
  .lg-ml-9 {
    margin-left: 2.25rem !important;
  }
  .lg-mt-10 {
    margin-top: 2.5rem !important;
  }
  .lg-mr-10 {
    margin-right: 2.5rem !important;
  }
  .lg-mb-10 {
    margin-bottom: 2.5rem !important;
  }
  .lg-ml-10 {
    margin-left: 2.5rem !important;
  }
  .lg-mt-11 {
    margin-top: 2.75rem !important;
  }
  .lg-mr-11 {
    margin-right: 2.75rem !important;
  }
  .lg-mb-11 {
    margin-bottom: 2.75rem !important;
  }
  .lg-ml-11 {
    margin-left: 2.75rem !important;
  }
  .lg-mt-12 {
    margin-top: 3rem !important;
  }
  .lg-mr-12 {
    margin-right: 3rem !important;
  }
  .lg-mb-12 {
    margin-bottom: 3rem !important;
  }
  .lg-ml-12 {
    margin-left: 3rem !important;
  }
  .lg-mt-13 {
    margin-top: 3.25rem !important;
  }
  .lg-mr-13 {
    margin-right: 3.25rem !important;
  }
  .lg-mb-13 {
    margin-bottom: 3.25rem !important;
  }
  .lg-ml-13 {
    margin-left: 3.25rem !important;
  }
  .lg-mt-14 {
    margin-top: 3.5rem !important;
  }
  .lg-mr-14 {
    margin-right: 3.5rem !important;
  }
  .lg-mb-14 {
    margin-bottom: 3.5rem !important;
  }
  .lg-ml-14 {
    margin-left: 3.5rem !important;
  }
  .lg-mt-15 {
    margin-top: 3.75rem !important;
  }
  .lg-mr-15 {
    margin-right: 3.75rem !important;
  }
  .lg-mb-15 {
    margin-bottom: 3.75rem !important;
  }
  .lg-ml-15 {
    margin-left: 3.75rem !important;
  }
  .lg-mt-16 {
    margin-top: 4rem !important;
  }
  .lg-mr-16 {
    margin-right: 4rem !important;
  }
  .lg-mb-16 {
    margin-bottom: 4rem !important;
  }
  .lg-ml-16 {
    margin-left: 4rem !important;
  }
  .lg-mt-17 {
    margin-top: 4.25rem !important;
  }
  .lg-mr-17 {
    margin-right: 4.25rem !important;
  }
  .lg-mb-17 {
    margin-bottom: 4.25rem !important;
  }
  .lg-ml-17 {
    margin-left: 4.25rem !important;
  }
  .lg-mt-18 {
    margin-top: 4.5rem !important;
  }
  .lg-mr-18 {
    margin-right: 4.5rem !important;
  }
  .lg-mb-18 {
    margin-bottom: 4.5rem !important;
  }
  .lg-ml-18 {
    margin-left: 4.5rem !important;
  }
  .lg-mt-19 {
    margin-top: 4.75rem !important;
  }
  .lg-mr-19 {
    margin-right: 4.75rem !important;
  }
  .lg-mb-19 {
    margin-bottom: 4.75rem !important;
  }
  .lg-ml-19 {
    margin-left: 4.75rem !important;
  }
  .lg-mt-20 {
    margin-top: 5rem !important;
  }
  .lg-mr-20 {
    margin-right: 5rem !important;
  }
  .lg-mb-20 {
    margin-bottom: 5rem !important;
  }
  .lg-ml-20 {
    margin-left: 5rem !important;
  }
  .lg-pt-0 {
    padding-top: 0rem !important;
  }
  .lg-pr-0 {
    padding-right: 0rem !important;
  }
  .lg-pb-0 {
    padding-bottom: 0rem !important;
  }
  .lg-pl-0 {
    padding-left: 0rem !important;
  }
  .lg-pt-1 {
    padding-top: 0.25rem !important;
  }
  .lg-pr-1 {
    padding-right: 0.25rem !important;
  }
  .lg-pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .lg-pl-1 {
    padding-left: 0.25rem !important;
  }
  .lg-pt-2 {
    padding-top: 0.5rem !important;
  }
  .lg-pr-2 {
    padding-right: 0.5rem !important;
  }
  .lg-pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .lg-pl-2 {
    padding-left: 0.5rem !important;
  }
  .lg-pt-3 {
    padding-top: 0.75rem !important;
  }
  .lg-pr-3 {
    padding-right: 0.75rem !important;
  }
  .lg-pb-3 {
    padding-bottom: 0.75rem !important;
  }
  .lg-pl-3 {
    padding-left: 0.75rem !important;
  }
  .lg-pt-4 {
    padding-top: 1rem !important;
  }
  .lg-pr-4 {
    padding-right: 1rem !important;
  }
  .lg-pb-4 {
    padding-bottom: 1rem !important;
  }
  .lg-pl-4 {
    padding-left: 1rem !important;
  }
  .lg-pt-5 {
    padding-top: 1.25rem !important;
  }
  .lg-pr-5 {
    padding-right: 1.25rem !important;
  }
  .lg-pb-5 {
    padding-bottom: 1.25rem !important;
  }
  .lg-pl-5 {
    padding-left: 1.25rem !important;
  }
  .lg-pt-6 {
    padding-top: 1.5rem !important;
  }
  .lg-pr-6 {
    padding-right: 1.5rem !important;
  }
  .lg-pb-6 {
    padding-bottom: 1.5rem !important;
  }
  .lg-pl-6 {
    padding-left: 1.5rem !important;
  }
  .lg-pt-7 {
    padding-top: 1.75rem !important;
  }
  .lg-pr-7 {
    padding-right: 1.75rem !important;
  }
  .lg-pb-7 {
    padding-bottom: 1.75rem !important;
  }
  .lg-pl-7 {
    padding-left: 1.75rem !important;
  }
  .lg-pt-8 {
    padding-top: 2rem !important;
  }
  .lg-pr-8 {
    padding-right: 2rem !important;
  }
  .lg-pb-8 {
    padding-bottom: 2rem !important;
  }
  .lg-pl-8 {
    padding-left: 2rem !important;
  }
  .lg-pt-9 {
    padding-top: 2.25rem !important;
  }
  .lg-pr-9 {
    padding-right: 2.25rem !important;
  }
  .lg-pb-9 {
    padding-bottom: 2.25rem !important;
  }
  .lg-pl-9 {
    padding-left: 2.25rem !important;
  }
  .lg-pt-10 {
    padding-top: 2.5rem !important;
  }
  .lg-pr-10 {
    padding-right: 2.5rem !important;
  }
  .lg-pb-10 {
    padding-bottom: 2.5rem !important;
  }
  .lg-pl-10 {
    padding-left: 2.5rem !important;
  }
  .lg-pt-11 {
    padding-top: 2.75rem !important;
  }
  .lg-pr-11 {
    padding-right: 2.75rem !important;
  }
  .lg-pb-11 {
    padding-bottom: 2.75rem !important;
  }
  .lg-pl-11 {
    padding-left: 2.75rem !important;
  }
  .lg-pt-12 {
    padding-top: 3rem !important;
  }
  .lg-pr-12 {
    padding-right: 3rem !important;
  }
  .lg-pb-12 {
    padding-bottom: 3rem !important;
  }
  .lg-pl-12 {
    padding-left: 3rem !important;
  }
  .lg-pt-13 {
    padding-top: 3.25rem !important;
  }
  .lg-pr-13 {
    padding-right: 3.25rem !important;
  }
  .lg-pb-13 {
    padding-bottom: 3.25rem !important;
  }
  .lg-pl-13 {
    padding-left: 3.25rem !important;
  }
  .lg-pt-14 {
    padding-top: 3.5rem !important;
  }
  .lg-pr-14 {
    padding-right: 3.5rem !important;
  }
  .lg-pb-14 {
    padding-bottom: 3.5rem !important;
  }
  .lg-pl-14 {
    padding-left: 3.5rem !important;
  }
  .lg-pt-15 {
    padding-top: 3.75rem !important;
  }
  .lg-pr-15 {
    padding-right: 3.75rem !important;
  }
  .lg-pb-15 {
    padding-bottom: 3.75rem !important;
  }
  .lg-pl-15 {
    padding-left: 3.75rem !important;
  }
  .lg-pt-16 {
    padding-top: 4rem !important;
  }
  .lg-pr-16 {
    padding-right: 4rem !important;
  }
  .lg-pb-16 {
    padding-bottom: 4rem !important;
  }
  .lg-pl-16 {
    padding-left: 4rem !important;
  }
  .lg-pt-17 {
    padding-top: 4.25rem !important;
  }
  .lg-pr-17 {
    padding-right: 4.25rem !important;
  }
  .lg-pb-17 {
    padding-bottom: 4.25rem !important;
  }
  .lg-pl-17 {
    padding-left: 4.25rem !important;
  }
  .lg-pt-18 {
    padding-top: 4.5rem !important;
  }
  .lg-pr-18 {
    padding-right: 4.5rem !important;
  }
  .lg-pb-18 {
    padding-bottom: 4.5rem !important;
  }
  .lg-pl-18 {
    padding-left: 4.5rem !important;
  }
  .lg-pt-19 {
    padding-top: 4.75rem !important;
  }
  .lg-pr-19 {
    padding-right: 4.75rem !important;
  }
  .lg-pb-19 {
    padding-bottom: 4.75rem !important;
  }
  .lg-pl-19 {
    padding-left: 4.75rem !important;
  }
  .lg-pt-20 {
    padding-top: 5rem !important;
  }
  .lg-pr-20 {
    padding-right: 5rem !important;
  }
  .lg-pb-20 {
    padding-bottom: 5rem !important;
  }
  .lg-pl-20 {
    padding-left: 5rem !important;
  }
  .lg-font-xxs {
    font-size: 0.625rem !important;
    line-height: 1rem !important;
  }
  .lg-font-xs {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }
  .lg-font-sm {
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
  }
  .lg-font-normal {
    font-size: 1rem !important;
    line-height: 1.5 !important;
  }
  .lg-font-lg {
    font-size: 1.125rem !important;
    line-height: 1.5 !important;
  }
  .text-right-lg {
    text-align: right;
  }
  .hidden-lg {
    display: none !important;
  }
  .visible-lg {
    display: block !important;
  }
  .visible-lg[data-flex] {
    display: flex !important;
  }
}
@media screen and (min-width: 80em) {
  .xl-mt-0 {
    margin-top: 0rem !important;
  }
  .xl-mr-0 {
    margin-right: 0rem !important;
  }
  .xl-mb-0 {
    margin-bottom: 0rem !important;
  }
  .xl-ml-0 {
    margin-left: 0rem !important;
  }
  .xl-mt-1 {
    margin-top: 0.25rem !important;
  }
  .xl-mr-1 {
    margin-right: 0.25rem !important;
  }
  .xl-mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .xl-ml-1 {
    margin-left: 0.25rem !important;
  }
  .xl-mt-2 {
    margin-top: 0.5rem !important;
  }
  .xl-mr-2 {
    margin-right: 0.5rem !important;
  }
  .xl-mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .xl-ml-2 {
    margin-left: 0.5rem !important;
  }
  .xl-mt-3 {
    margin-top: 0.75rem !important;
  }
  .xl-mr-3 {
    margin-right: 0.75rem !important;
  }
  .xl-mb-3 {
    margin-bottom: 0.75rem !important;
  }
  .xl-ml-3 {
    margin-left: 0.75rem !important;
  }
  .xl-mt-4 {
    margin-top: 1rem !important;
  }
  .xl-mr-4 {
    margin-right: 1rem !important;
  }
  .xl-mb-4 {
    margin-bottom: 1rem !important;
  }
  .xl-ml-4 {
    margin-left: 1rem !important;
  }
  .xl-mt-5 {
    margin-top: 1.25rem !important;
  }
  .xl-mr-5 {
    margin-right: 1.25rem !important;
  }
  .xl-mb-5 {
    margin-bottom: 1.25rem !important;
  }
  .xl-ml-5 {
    margin-left: 1.25rem !important;
  }
  .xl-mt-6 {
    margin-top: 1.5rem !important;
  }
  .xl-mr-6 {
    margin-right: 1.5rem !important;
  }
  .xl-mb-6 {
    margin-bottom: 1.5rem !important;
  }
  .xl-ml-6 {
    margin-left: 1.5rem !important;
  }
  .xl-mt-7 {
    margin-top: 1.75rem !important;
  }
  .xl-mr-7 {
    margin-right: 1.75rem !important;
  }
  .xl-mb-7 {
    margin-bottom: 1.75rem !important;
  }
  .xl-ml-7 {
    margin-left: 1.75rem !important;
  }
  .xl-mt-8 {
    margin-top: 2rem !important;
  }
  .xl-mr-8 {
    margin-right: 2rem !important;
  }
  .xl-mb-8 {
    margin-bottom: 2rem !important;
  }
  .xl-ml-8 {
    margin-left: 2rem !important;
  }
  .xl-mt-9 {
    margin-top: 2.25rem !important;
  }
  .xl-mr-9 {
    margin-right: 2.25rem !important;
  }
  .xl-mb-9 {
    margin-bottom: 2.25rem !important;
  }
  .xl-ml-9 {
    margin-left: 2.25rem !important;
  }
  .xl-mt-10 {
    margin-top: 2.5rem !important;
  }
  .xl-mr-10 {
    margin-right: 2.5rem !important;
  }
  .xl-mb-10 {
    margin-bottom: 2.5rem !important;
  }
  .xl-ml-10 {
    margin-left: 2.5rem !important;
  }
  .xl-mt-11 {
    margin-top: 2.75rem !important;
  }
  .xl-mr-11 {
    margin-right: 2.75rem !important;
  }
  .xl-mb-11 {
    margin-bottom: 2.75rem !important;
  }
  .xl-ml-11 {
    margin-left: 2.75rem !important;
  }
  .xl-mt-12 {
    margin-top: 3rem !important;
  }
  .xl-mr-12 {
    margin-right: 3rem !important;
  }
  .xl-mb-12 {
    margin-bottom: 3rem !important;
  }
  .xl-ml-12 {
    margin-left: 3rem !important;
  }
  .xl-mt-13 {
    margin-top: 3.25rem !important;
  }
  .xl-mr-13 {
    margin-right: 3.25rem !important;
  }
  .xl-mb-13 {
    margin-bottom: 3.25rem !important;
  }
  .xl-ml-13 {
    margin-left: 3.25rem !important;
  }
  .xl-mt-14 {
    margin-top: 3.5rem !important;
  }
  .xl-mr-14 {
    margin-right: 3.5rem !important;
  }
  .xl-mb-14 {
    margin-bottom: 3.5rem !important;
  }
  .xl-ml-14 {
    margin-left: 3.5rem !important;
  }
  .xl-mt-15 {
    margin-top: 3.75rem !important;
  }
  .xl-mr-15 {
    margin-right: 3.75rem !important;
  }
  .xl-mb-15 {
    margin-bottom: 3.75rem !important;
  }
  .xl-ml-15 {
    margin-left: 3.75rem !important;
  }
  .xl-mt-16 {
    margin-top: 4rem !important;
  }
  .xl-mr-16 {
    margin-right: 4rem !important;
  }
  .xl-mb-16 {
    margin-bottom: 4rem !important;
  }
  .xl-ml-16 {
    margin-left: 4rem !important;
  }
  .xl-mt-17 {
    margin-top: 4.25rem !important;
  }
  .xl-mr-17 {
    margin-right: 4.25rem !important;
  }
  .xl-mb-17 {
    margin-bottom: 4.25rem !important;
  }
  .xl-ml-17 {
    margin-left: 4.25rem !important;
  }
  .xl-mt-18 {
    margin-top: 4.5rem !important;
  }
  .xl-mr-18 {
    margin-right: 4.5rem !important;
  }
  .xl-mb-18 {
    margin-bottom: 4.5rem !important;
  }
  .xl-ml-18 {
    margin-left: 4.5rem !important;
  }
  .xl-mt-19 {
    margin-top: 4.75rem !important;
  }
  .xl-mr-19 {
    margin-right: 4.75rem !important;
  }
  .xl-mb-19 {
    margin-bottom: 4.75rem !important;
  }
  .xl-ml-19 {
    margin-left: 4.75rem !important;
  }
  .xl-mt-20 {
    margin-top: 5rem !important;
  }
  .xl-mr-20 {
    margin-right: 5rem !important;
  }
  .xl-mb-20 {
    margin-bottom: 5rem !important;
  }
  .xl-ml-20 {
    margin-left: 5rem !important;
  }
  .xl-pt-0 {
    padding-top: 0rem !important;
  }
  .xl-pr-0 {
    padding-right: 0rem !important;
  }
  .xl-pb-0 {
    padding-bottom: 0rem !important;
  }
  .xl-pl-0 {
    padding-left: 0rem !important;
  }
  .xl-pt-1 {
    padding-top: 0.25rem !important;
  }
  .xl-pr-1 {
    padding-right: 0.25rem !important;
  }
  .xl-pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .xl-pl-1 {
    padding-left: 0.25rem !important;
  }
  .xl-pt-2 {
    padding-top: 0.5rem !important;
  }
  .xl-pr-2 {
    padding-right: 0.5rem !important;
  }
  .xl-pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .xl-pl-2 {
    padding-left: 0.5rem !important;
  }
  .xl-pt-3 {
    padding-top: 0.75rem !important;
  }
  .xl-pr-3 {
    padding-right: 0.75rem !important;
  }
  .xl-pb-3 {
    padding-bottom: 0.75rem !important;
  }
  .xl-pl-3 {
    padding-left: 0.75rem !important;
  }
  .xl-pt-4 {
    padding-top: 1rem !important;
  }
  .xl-pr-4 {
    padding-right: 1rem !important;
  }
  .xl-pb-4 {
    padding-bottom: 1rem !important;
  }
  .xl-pl-4 {
    padding-left: 1rem !important;
  }
  .xl-pt-5 {
    padding-top: 1.25rem !important;
  }
  .xl-pr-5 {
    padding-right: 1.25rem !important;
  }
  .xl-pb-5 {
    padding-bottom: 1.25rem !important;
  }
  .xl-pl-5 {
    padding-left: 1.25rem !important;
  }
  .xl-pt-6 {
    padding-top: 1.5rem !important;
  }
  .xl-pr-6 {
    padding-right: 1.5rem !important;
  }
  .xl-pb-6 {
    padding-bottom: 1.5rem !important;
  }
  .xl-pl-6 {
    padding-left: 1.5rem !important;
  }
  .xl-pt-7 {
    padding-top: 1.75rem !important;
  }
  .xl-pr-7 {
    padding-right: 1.75rem !important;
  }
  .xl-pb-7 {
    padding-bottom: 1.75rem !important;
  }
  .xl-pl-7 {
    padding-left: 1.75rem !important;
  }
  .xl-pt-8 {
    padding-top: 2rem !important;
  }
  .xl-pr-8 {
    padding-right: 2rem !important;
  }
  .xl-pb-8 {
    padding-bottom: 2rem !important;
  }
  .xl-pl-8 {
    padding-left: 2rem !important;
  }
  .xl-pt-9 {
    padding-top: 2.25rem !important;
  }
  .xl-pr-9 {
    padding-right: 2.25rem !important;
  }
  .xl-pb-9 {
    padding-bottom: 2.25rem !important;
  }
  .xl-pl-9 {
    padding-left: 2.25rem !important;
  }
  .xl-pt-10 {
    padding-top: 2.5rem !important;
  }
  .xl-pr-10 {
    padding-right: 2.5rem !important;
  }
  .xl-pb-10 {
    padding-bottom: 2.5rem !important;
  }
  .xl-pl-10 {
    padding-left: 2.5rem !important;
  }
  .xl-pt-11 {
    padding-top: 2.75rem !important;
  }
  .xl-pr-11 {
    padding-right: 2.75rem !important;
  }
  .xl-pb-11 {
    padding-bottom: 2.75rem !important;
  }
  .xl-pl-11 {
    padding-left: 2.75rem !important;
  }
  .xl-pt-12 {
    padding-top: 3rem !important;
  }
  .xl-pr-12 {
    padding-right: 3rem !important;
  }
  .xl-pb-12 {
    padding-bottom: 3rem !important;
  }
  .xl-pl-12 {
    padding-left: 3rem !important;
  }
  .xl-pt-13 {
    padding-top: 3.25rem !important;
  }
  .xl-pr-13 {
    padding-right: 3.25rem !important;
  }
  .xl-pb-13 {
    padding-bottom: 3.25rem !important;
  }
  .xl-pl-13 {
    padding-left: 3.25rem !important;
  }
  .xl-pt-14 {
    padding-top: 3.5rem !important;
  }
  .xl-pr-14 {
    padding-right: 3.5rem !important;
  }
  .xl-pb-14 {
    padding-bottom: 3.5rem !important;
  }
  .xl-pl-14 {
    padding-left: 3.5rem !important;
  }
  .xl-pt-15 {
    padding-top: 3.75rem !important;
  }
  .xl-pr-15 {
    padding-right: 3.75rem !important;
  }
  .xl-pb-15 {
    padding-bottom: 3.75rem !important;
  }
  .xl-pl-15 {
    padding-left: 3.75rem !important;
  }
  .xl-pt-16 {
    padding-top: 4rem !important;
  }
  .xl-pr-16 {
    padding-right: 4rem !important;
  }
  .xl-pb-16 {
    padding-bottom: 4rem !important;
  }
  .xl-pl-16 {
    padding-left: 4rem !important;
  }
  .xl-pt-17 {
    padding-top: 4.25rem !important;
  }
  .xl-pr-17 {
    padding-right: 4.25rem !important;
  }
  .xl-pb-17 {
    padding-bottom: 4.25rem !important;
  }
  .xl-pl-17 {
    padding-left: 4.25rem !important;
  }
  .xl-pt-18 {
    padding-top: 4.5rem !important;
  }
  .xl-pr-18 {
    padding-right: 4.5rem !important;
  }
  .xl-pb-18 {
    padding-bottom: 4.5rem !important;
  }
  .xl-pl-18 {
    padding-left: 4.5rem !important;
  }
  .xl-pt-19 {
    padding-top: 4.75rem !important;
  }
  .xl-pr-19 {
    padding-right: 4.75rem !important;
  }
  .xl-pb-19 {
    padding-bottom: 4.75rem !important;
  }
  .xl-pl-19 {
    padding-left: 4.75rem !important;
  }
  .xl-pt-20 {
    padding-top: 5rem !important;
  }
  .xl-pr-20 {
    padding-right: 5rem !important;
  }
  .xl-pb-20 {
    padding-bottom: 5rem !important;
  }
  .xl-pl-20 {
    padding-left: 5rem !important;
  }
  .xl-font-xxs {
    font-size: 0.625rem !important;
    line-height: 1rem !important;
  }
  .xl-font-xs {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }
  .xl-font-sm {
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
  }
  .xl-font-normal {
    font-size: 1rem !important;
    line-height: 1.5 !important;
  }
  .xl-font-lg {
    font-size: 1.125rem !important;
    line-height: 1.5 !important;
  }
  .text-right-xl {
    text-align: right;
  }
  .hidden-xl {
    display: none !important;
  }
  .visible-xl {
    display: block !important;
  }
  .visible-xl[data-flex] {
    display: flex !important;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.rotate {
  animation: rotate 2s linear infinite;
}

.pure-form input[type=text],
.pure-form input[type=number],
.pure-form input[type=search],
.pure-form input[type=tel],
.pure-form input[type=color],
.pure-form input[type=password],
.pure-form input[type=email],
.pure-form input[type=url],
.pure-form input[type=date],
.pure-form input[type=month],
.pure-form input[type=time],
.pure-form input[type=datetime],
.pure-form input[type=datetime-local],
.pure-form input[type=week],
.pure-form select,
.pure-form textarea {
  color: var(--color-primary);
}
.pure-form input[type=text][disabled],
.pure-form input[type=number][disabled],
.pure-form input[type=search][disabled],
.pure-form input[type=tel][disabled],
.pure-form input[type=color][disabled],
.pure-form input[type=password][disabled],
.pure-form input[type=email][disabled],
.pure-form input[type=url][disabled],
.pure-form input[type=date][disabled],
.pure-form input[type=month][disabled],
.pure-form input[type=time][disabled],
.pure-form input[type=datetime][disabled],
.pure-form input[type=datetime-local][disabled],
.pure-form input[type=week][disabled],
.pure-form select[disabled],
.pure-form textarea[disabled] {
  color: var(--color-primary);
}

.pure-menu-heading {
  font-weight: 500;
  color: var(--color-primary);
}

.pure-menu-link {
  color: var(--color-primary);
}
.pure-menu-link:hover, .pure-menu-link:focus {
  color: var(--color-primary-contrast);
  background-color: var(--color-primary);
  text-decoration: none;
}

.pure-menu-children {
  background: var(--color-primary);
  color: var(--color-primary-contrast);
  border-top: solid 4px var(--color-primary-light);
  min-width: 100%;
}
.pure-menu-children .pure-menu-link {
  background: var(--color-primary);
  color: var(--color-primary-contrast);
}
.pure-menu-children .pure-menu-link:hover, .pure-menu-children .pure-menu-link:focus {
  background: none;
  text-decoration: underline;
}

.pure-offset-1-24 {
  margin-left: 4.1667%;
  *margin-left: 4.1357%;
}

.pure-offset-1-12,
.pure-offset-2-24 {
  margin-left: 8.3333%;
  *margin-left: 8.3023%;
}

.pure-offset-1-8,
.pure-offset-3-24 {
  margin-left: 12.5%;
  *margin-left: 12.469%;
}

.pure-offset-1-6,
.pure-offset-4-24 {
  margin-left: 16.6667%;
  *margin-left: 16.6357%;
}

.pure-offset-1-5 {
  margin-left: 20%;
  *margin-left: 19.969%;
}

.pure-offset-5-24 {
  margin-left: 20.8333%;
  *margin-left: 20.8023%;
}

.pure-offset-1-4,
.pure-offset-6-24 {
  margin-left: 25%;
  *margin-left: 24.969%;
}

.pure-offset-7-24 {
  margin-left: 29.1667%;
  *margin-left: 29.1357%;
}

.pure-offset-1-3,
.pure-offset-8-24 {
  margin-left: 33.3333%;
  *margin-left: 33.3023%;
}

.pure-offset-3-8,
.pure-offset-9-24 {
  margin-left: 37.5%;
  *margin-left: 37.469%;
}

.pure-offset-2-5 {
  margin-left: 40%;
  *margin-left: 39.969%;
}

.pure-offset-5-12,
.pure-offset-10-24 {
  margin-left: 41.6667%;
  *margin-left: 41.6357%;
}

.pure-offset-11-24 {
  margin-left: 45.8333%;
  *margin-left: 45.8023%;
}

.pure-offset-1-2,
.pure-offset-12-24 {
  margin-left: 50%;
  *margin-left: 49.969%;
}

.pure-offset-13-24 {
  margin-left: 54.1667%;
  *margin-left: 54.1357%;
}

.pure-offset-7-12,
.pure-offset-14-24 {
  margin-left: 58.3333%;
  *margin-left: 58.3023%;
}

.pure-offset-3-5 {
  margin-left: 60%;
  *margin-left: 59.969%;
}

.pure-offset-5-8,
.pure-offset-15-24 {
  margin-left: 62.5%;
  *margin-left: 62.469%;
}

.pure-offset-2-3,
.pure-offset-16-24 {
  margin-left: 66.6667%;
  *margin-left: 66.6357%;
}

.pure-offset-17-24 {
  margin-left: 70.8333%;
  *margin-left: 70.8023%;
}

.pure-offset-3-4,
.pure-offset-18-24 {
  margin-left: 75%;
  *margin-left: 74.969%;
}

.pure-offset-19-24 {
  margin-left: 79.1667%;
  *margin-left: 79.1357%;
}

.pure-offset-4-5 {
  margin-left: 80%;
  *margin-left: 79.969%;
}

.pure-offset-5-6,
.pure-offset-20-24 {
  margin-left: 83.3333%;
  *margin-left: 83.3023%;
}

.pure-offset-7-8,
.pure-offset-21-24 {
  margin-left: 87.5%;
  *margin-left: 87.469%;
}

.pure-offset-11-12,
.pure-offset-22-24 {
  margin-left: 91.6667%;
  *margin-left: 91.6357%;
}

.pure-offset-23-24 {
  margin-left: 95.8333%;
  *margin-left: 95.8023%;
}

@media screen and (min-width: 35.5em) {
  .pure-offset-sm-1,
  .pure-offset-sm-1-1,
  .pure-offset-sm-1-2,
  .pure-offset-sm-1-3,
  .pure-offset-sm-2-3,
  .pure-offset-sm-1-4,
  .pure-offset-sm-3-4,
  .pure-offset-sm-1-5,
  .pure-offset-sm-2-5,
  .pure-offset-sm-3-5,
  .pure-offset-sm-4-5,
  .pure-offset-sm-5-5,
  .pure-offset-sm-1-6,
  .pure-offset-sm-5-6,
  .pure-offset-sm-1-8,
  .pure-offset-sm-3-8,
  .pure-offset-sm-5-8,
  .pure-offset-sm-7-8,
  .pure-offset-sm-1-12,
  .pure-offset-sm-5-12,
  .pure-offset-sm-7-12,
  .pure-offset-sm-11-12,
  .pure-offset-sm-1-24,
  .pure-offset-sm-2-24,
  .pure-offset-sm-3-24,
  .pure-offset-sm-4-24,
  .pure-offset-sm-5-24,
  .pure-offset-sm-6-24,
  .pure-offset-sm-7-24,
  .pure-offset-sm-8-24,
  .pure-offset-sm-9-24,
  .pure-offset-sm-10-24,
  .pure-offset-sm-11-24,
  .pure-offset-sm-12-24,
  .pure-offset-sm-13-24,
  .pure-offset-sm-14-24,
  .pure-offset-sm-15-24,
  .pure-offset-sm-16-24,
  .pure-offset-sm-17-24,
  .pure-offset-sm-18-24,
  .pure-offset-sm-19-24,
  .pure-offset-sm-20-24,
  .pure-offset-sm-21-24,
  .pure-offset-sm-22-24,
  .pure-offset-sm-23-24,
  .pure-offset-sm-24-24 {
    display: inline-block;
    *display: inline;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
  }
  .pure-offset-sm-1-24 {
    margin-left: 4.1667%;
    *margin-left: 4.1357%;
  }
  .pure-offset-sm-1-12,
  .pure-offset-sm-2-24 {
    margin-left: 8.3333%;
    *margin-left: 8.3023%;
  }
  .pure-offset-sm-1-8,
  .pure-offset-sm-3-24 {
    margin-left: 12.5%;
    *margin-left: 12.469%;
  }
  .pure-offset-sm-1-6,
  .pure-offset-sm-4-24 {
    margin-left: 16.6667%;
    *margin-left: 16.6357%;
  }
  .pure-offset-sm-1-5 {
    margin-left: 20%;
    *margin-left: 19.969%;
  }
  .pure-offset-sm-5-24 {
    margin-left: 20.8333%;
    *margin-left: 20.8023%;
  }
  .pure-offset-sm-1-4,
  .pure-offset-sm-6-24 {
    margin-left: 25%;
    *margin-left: 24.969%;
  }
  .pure-offset-sm-7-24 {
    margin-left: 29.1667%;
    *margin-left: 29.1357%;
  }
  .pure-offset-sm-1-3,
  .pure-offset-sm-8-24 {
    margin-left: 33.3333%;
    *margin-left: 33.3023%;
  }
  .pure-offset-sm-3-8,
  .pure-offset-sm-9-24 {
    margin-left: 37.5%;
    *margin-left: 37.469%;
  }
  .pure-offset-sm-2-5 {
    margin-left: 40%;
    *margin-left: 39.969%;
  }
  .pure-offset-sm-5-12,
  .pure-offset-sm-10-24 {
    margin-left: 41.6667%;
    *margin-left: 41.6357%;
  }
  .pure-offset-sm-11-24 {
    margin-left: 45.8333%;
    *margin-left: 45.8023%;
  }
  .pure-offset-sm-1-2,
  .pure-offset-sm-12-24 {
    margin-left: 50%;
    *margin-left: 49.969%;
  }
  .pure-offset-sm-13-24 {
    margin-left: 54.1667%;
    *margin-left: 54.1357%;
  }
  .pure-offset-sm-7-12,
  .pure-offset-sm-14-24 {
    margin-left: 58.3333%;
    *margin-left: 58.3023%;
  }
  .pure-offset-sm-3-5 {
    margin-left: 60%;
    *margin-left: 59.969%;
  }
  .pure-offset-sm-5-8,
  .pure-offset-sm-15-24 {
    margin-left: 62.5%;
    *margin-left: 62.469%;
  }
  .pure-offset-sm-2-3,
  .pure-offset-sm-16-24 {
    margin-left: 66.6667%;
    *margin-left: 66.6357%;
  }
  .pure-offset-sm-17-24 {
    margin-left: 70.8333%;
    *margin-left: 70.8023%;
  }
  .pure-offset-sm-3-4,
  .pure-offset-sm-18-24 {
    margin-left: 75%;
    *margin-left: 74.969%;
  }
  .pure-offset-sm-19-24 {
    margin-left: 79.1667%;
    *margin-left: 79.1357%;
  }
  .pure-offset-sm-4-5 {
    margin-left: 80%;
    *margin-left: 79.969%;
  }
  .pure-offset-sm-5-6,
  .pure-offset-sm-20-24 {
    margin-left: 83.3333%;
    *margin-left: 83.3023%;
  }
  .pure-offset-sm-7-8,
  .pure-offset-sm-21-24 {
    margin-left: 87.5%;
    *margin-left: 87.469%;
  }
  .pure-offset-sm-11-12,
  .pure-offset-sm-22-24 {
    margin-left: 91.6667%;
    *margin-left: 91.6357%;
  }
  .pure-offset-sm-23-24 {
    margin-left: 95.8333%;
    *margin-left: 95.8023%;
  }
  .pure-offset-sm-1,
  .pure-offset-sm-1-1,
  .pure-offset-sm-5-5,
  .pure-offset-sm-24-24 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 48em) {
  .pure-offset-md-1,
  .pure-offset-md-1-1,
  .pure-offset-md-1-2,
  .pure-offset-md-1-3,
  .pure-offset-md-2-3,
  .pure-offset-md-1-4,
  .pure-offset-md-3-4,
  .pure-offset-md-1-5,
  .pure-offset-md-2-5,
  .pure-offset-md-3-5,
  .pure-offset-md-4-5,
  .pure-offset-md-5-5,
  .pure-offset-md-1-6,
  .pure-offset-md-5-6,
  .pure-offset-md-1-8,
  .pure-offset-md-3-8,
  .pure-offset-md-5-8,
  .pure-offset-md-7-8,
  .pure-offset-md-1-12,
  .pure-offset-md-5-12,
  .pure-offset-md-7-12,
  .pure-offset-md-11-12,
  .pure-offset-md-1-24,
  .pure-offset-md-2-24,
  .pure-offset-md-3-24,
  .pure-offset-md-4-24,
  .pure-offset-md-5-24,
  .pure-offset-md-6-24,
  .pure-offset-md-7-24,
  .pure-offset-md-8-24,
  .pure-offset-md-9-24,
  .pure-offset-md-10-24,
  .pure-offset-md-11-24,
  .pure-offset-md-12-24,
  .pure-offset-md-13-24,
  .pure-offset-md-14-24,
  .pure-offset-md-15-24,
  .pure-offset-md-16-24,
  .pure-offset-md-17-24,
  .pure-offset-md-18-24,
  .pure-offset-md-19-24,
  .pure-offset-md-20-24,
  .pure-offset-md-21-24,
  .pure-offset-md-22-24,
  .pure-offset-md-23-24,
  .pure-offset-md-24-24 {
    display: inline-block;
    *display: inline;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
  }
  .pure-offset-md-1-24 {
    margin-left: 4.1667%;
    *margin-left: 4.1357%;
  }
  .pure-offset-md-1-12,
  .pure-offset-md-2-24 {
    margin-left: 8.3333%;
    *margin-left: 8.3023%;
  }
  .pure-offset-md-1-8,
  .pure-offset-md-3-24 {
    margin-left: 12.5%;
    *margin-left: 12.469%;
  }
  .pure-offset-md-1-6,
  .pure-offset-md-4-24 {
    margin-left: 16.6667%;
    *margin-left: 16.6357%;
  }
  .pure-offset-md-1-5 {
    margin-left: 20%;
    *margin-left: 19.969%;
  }
  .pure-offset-md-5-24 {
    margin-left: 20.8333%;
    *margin-left: 20.8023%;
  }
  .pure-offset-md-1-4,
  .pure-offset-md-6-24 {
    margin-left: 25%;
    *margin-left: 24.969%;
  }
  .pure-offset-md-7-24 {
    margin-left: 29.1667%;
    *margin-left: 29.1357%;
  }
  .pure-offset-md-1-3,
  .pure-offset-md-8-24 {
    margin-left: 33.3333%;
    *margin-left: 33.3023%;
  }
  .pure-offset-md-3-8,
  .pure-offset-md-9-24 {
    margin-left: 37.5%;
    *margin-left: 37.469%;
  }
  .pure-offset-md-2-5 {
    margin-left: 40%;
    *margin-left: 39.969%;
  }
  .pure-offset-md-5-12,
  .pure-offset-md-10-24 {
    margin-left: 41.6667%;
    *margin-left: 41.6357%;
  }
  .pure-offset-md-11-24 {
    margin-left: 45.8333%;
    *margin-left: 45.8023%;
  }
  .pure-offset-md-1-2,
  .pure-offset-md-12-24 {
    margin-left: 50%;
    *margin-left: 49.969%;
  }
  .pure-offset-md-13-24 {
    margin-left: 54.1667%;
    *margin-left: 54.1357%;
  }
  .pure-offset-md-7-12,
  .pure-offset-md-14-24 {
    margin-left: 58.3333%;
    *margin-left: 58.3023%;
  }
  .pure-offset-md-3-5 {
    margin-left: 60%;
    *margin-left: 59.969%;
  }
  .pure-offset-md-5-8,
  .pure-offset-md-15-24 {
    margin-left: 62.5%;
    *margin-left: 62.469%;
  }
  .pure-offset-md-2-3,
  .pure-offset-md-16-24 {
    margin-left: 66.6667%;
    *margin-left: 66.6357%;
  }
  .pure-offset-md-17-24 {
    margin-left: 70.8333%;
    *margin-left: 70.8023%;
  }
  .pure-offset-md-3-4,
  .pure-offset-md-18-24 {
    margin-left: 75%;
    *margin-left: 74.969%;
  }
  .pure-offset-md-19-24 {
    margin-left: 79.1667%;
    *margin-left: 79.1357%;
  }
  .pure-offset-md-4-5 {
    margin-left: 80%;
    *margin-left: 79.969%;
  }
  .pure-offset-md-5-6,
  .pure-offset-md-20-24 {
    margin-left: 83.3333%;
    *margin-left: 83.3023%;
  }
  .pure-offset-md-7-8,
  .pure-offset-md-21-24 {
    margin-left: 87.5%;
    *margin-left: 87.469%;
  }
  .pure-offset-md-11-12,
  .pure-offset-md-22-24 {
    margin-left: 91.6667%;
    *margin-left: 91.6357%;
  }
  .pure-offset-md-23-24 {
    margin-left: 95.8333%;
    *margin-left: 95.8023%;
  }
  .pure-offset-md-1,
  .pure-offset-md-1-1,
  .pure-offset-md-5-5,
  .pure-offset-md-24-24 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 64em) {
  .pure-offset-lg-1,
  .pure-offset-lg-1-1,
  .pure-offset-lg-1-2,
  .pure-offset-lg-1-3,
  .pure-offset-lg-2-3,
  .pure-offset-lg-1-4,
  .pure-offset-lg-3-4,
  .pure-offset-lg-1-5,
  .pure-offset-lg-2-5,
  .pure-offset-lg-3-5,
  .pure-offset-lg-4-5,
  .pure-offset-lg-5-5,
  .pure-offset-lg-1-6,
  .pure-offset-lg-5-6,
  .pure-offset-lg-1-8,
  .pure-offset-lg-3-8,
  .pure-offset-lg-5-8,
  .pure-offset-lg-7-8,
  .pure-offset-lg-1-12,
  .pure-offset-lg-5-12,
  .pure-offset-lg-7-12,
  .pure-offset-lg-11-12,
  .pure-offset-lg-1-24,
  .pure-offset-lg-2-24,
  .pure-offset-lg-3-24,
  .pure-offset-lg-4-24,
  .pure-offset-lg-5-24,
  .pure-offset-lg-6-24,
  .pure-offset-lg-7-24,
  .pure-offset-lg-8-24,
  .pure-offset-lg-9-24,
  .pure-offset-lg-10-24,
  .pure-offset-lg-11-24,
  .pure-offset-lg-12-24,
  .pure-offset-lg-13-24,
  .pure-offset-lg-14-24,
  .pure-offset-lg-15-24,
  .pure-offset-lg-16-24,
  .pure-offset-lg-17-24,
  .pure-offset-lg-18-24,
  .pure-offset-lg-19-24,
  .pure-offset-lg-20-24,
  .pure-offset-lg-21-24,
  .pure-offset-lg-22-24,
  .pure-offset-lg-23-24,
  .pure-offset-lg-24-24 {
    display: inline-block;
    *display: inline;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
  }
  .pure-offset-lg-1-24 {
    margin-left: 4.1667%;
    *margin-left: 4.1357%;
  }
  .pure-offset-lg-1-12,
  .pure-offset-lg-2-24 {
    margin-left: 8.3333%;
    *margin-left: 8.3023%;
  }
  .pure-offset-lg-1-8,
  .pure-offset-lg-3-24 {
    margin-left: 12.5%;
    *margin-left: 12.469%;
  }
  .pure-offset-lg-1-6,
  .pure-offset-lg-4-24 {
    margin-left: 16.6667%;
    *margin-left: 16.6357%;
  }
  .pure-offset-lg-1-5 {
    margin-left: 20%;
    *margin-left: 19.969%;
  }
  .pure-offset-lg-5-24 {
    margin-left: 20.8333%;
    *margin-left: 20.8023%;
  }
  .pure-offset-lg-1-4,
  .pure-offset-lg-6-24 {
    margin-left: 25%;
    *margin-left: 24.969%;
  }
  .pure-offset-lg-7-24 {
    margin-left: 29.1667%;
    *margin-left: 29.1357%;
  }
  .pure-offset-lg-1-3,
  .pure-offset-lg-8-24 {
    margin-left: 33.3333%;
    *margin-left: 33.3023%;
  }
  .pure-offset-lg-3-8,
  .pure-offset-lg-9-24 {
    margin-left: 37.5%;
    *margin-left: 37.469%;
  }
  .pure-offset-lg-2-5 {
    margin-left: 40%;
    *margin-left: 39.969%;
  }
  .pure-offset-lg-5-12,
  .pure-offset-lg-10-24 {
    margin-left: 41.6667%;
    *margin-left: 41.6357%;
  }
  .pure-offset-lg-11-24 {
    margin-left: 45.8333%;
    *margin-left: 45.8023%;
  }
  .pure-offset-lg-1-2,
  .pure-offset-lg-12-24 {
    margin-left: 50%;
    *margin-left: 49.969%;
  }
  .pure-offset-lg-13-24 {
    margin-left: 54.1667%;
    *margin-left: 54.1357%;
  }
  .pure-offset-lg-7-12,
  .pure-offset-lg-14-24 {
    margin-left: 58.3333%;
    *margin-left: 58.3023%;
  }
  .pure-offset-lg-3-5 {
    margin-left: 60%;
    *margin-left: 59.969%;
  }
  .pure-offset-lg-5-8,
  .pure-offset-lg-15-24 {
    margin-left: 62.5%;
    *margin-left: 62.469%;
  }
  .pure-offset-lg-2-3,
  .pure-offset-lg-16-24 {
    margin-left: 66.6667%;
    *margin-left: 66.6357%;
  }
  .pure-offset-lg-17-24 {
    margin-left: 70.8333%;
    *margin-left: 70.8023%;
  }
  .pure-offset-lg-3-4,
  .pure-offset-lg-18-24 {
    margin-left: 75%;
    *margin-left: 74.969%;
  }
  .pure-offset-lg-19-24 {
    margin-left: 79.1667%;
    *margin-left: 79.1357%;
  }
  .pure-offset-lg-4-5 {
    margin-left: 80%;
    *margin-left: 79.969%;
  }
  .pure-offset-lg-5-6,
  .pure-offset-lg-20-24 {
    margin-left: 83.3333%;
    *margin-left: 83.3023%;
  }
  .pure-offset-lg-7-8,
  .pure-offset-lg-21-24 {
    margin-left: 87.5%;
    *margin-left: 87.469%;
  }
  .pure-offset-lg-11-12,
  .pure-offset-lg-22-24 {
    margin-left: 91.6667%;
    *margin-left: 91.6357%;
  }
  .pure-offset-lg-23-24 {
    margin-left: 95.8333%;
    *margin-left: 95.8023%;
  }
  .pure-offset-lg-1,
  .pure-offset-lg-1-1,
  .pure-offset-lg-5-5,
  .pure-offset-lg-24-24 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 80em) {
  .pure-offset-xl-1,
  .pure-offset-xl-1-1,
  .pure-offset-xl-1-2,
  .pure-offset-xl-1-3,
  .pure-offset-xl-2-3,
  .pure-offset-xl-1-4,
  .pure-offset-xl-3-4,
  .pure-offset-xl-1-5,
  .pure-offset-xl-2-5,
  .pure-offset-xl-3-5,
  .pure-offset-xl-4-5,
  .pure-offset-xl-5-5,
  .pure-offset-xl-1-6,
  .pure-offset-xl-5-6,
  .pure-offset-xl-1-8,
  .pure-offset-xl-3-8,
  .pure-offset-xl-5-8,
  .pure-offset-xl-7-8,
  .pure-offset-xl-1-12,
  .pure-offset-xl-5-12,
  .pure-offset-xl-7-12,
  .pure-offset-xl-11-12,
  .pure-offset-xl-1-24,
  .pure-offset-xl-2-24,
  .pure-offset-xl-3-24,
  .pure-offset-xl-4-24,
  .pure-offset-xl-5-24,
  .pure-offset-xl-6-24,
  .pure-offset-xl-7-24,
  .pure-offset-xl-8-24,
  .pure-offset-xl-9-24,
  .pure-offset-xl-10-24,
  .pure-offset-xl-11-24,
  .pure-offset-xl-12-24,
  .pure-offset-xl-13-24,
  .pure-offset-xl-14-24,
  .pure-offset-xl-15-24,
  .pure-offset-xl-16-24,
  .pure-offset-xl-17-24,
  .pure-offset-xl-18-24,
  .pure-offset-xl-19-24,
  .pure-offset-xl-20-24,
  .pure-offset-xl-21-24,
  .pure-offset-xl-22-24,
  .pure-offset-xl-23-24,
  .pure-offset-xl-24-24 {
    display: inline-block;
    *display: inline;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
  }
  .pure-offset-xl-1-24 {
    margin-left: 4.1667%;
    *margin-left: 4.1357%;
  }
  .pure-offset-xl-1-12,
  .pure-offset-xl-2-24 {
    margin-left: 8.3333%;
    *margin-left: 8.3023%;
  }
  .pure-offset-xl-1-8,
  .pure-offset-xl-3-24 {
    margin-left: 12.5%;
    *margin-left: 12.469%;
  }
  .pure-offset-xl-1-6,
  .pure-offset-xl-4-24 {
    margin-left: 16.6667%;
    *margin-left: 16.6357%;
  }
  .pure-offset-xl-1-5 {
    margin-left: 20%;
    *margin-left: 19.969%;
  }
  .pure-offset-xl-5-24 {
    margin-left: 20.8333%;
    *margin-left: 20.8023%;
  }
  .pure-offset-xl-1-4,
  .pure-offset-xl-6-24 {
    margin-left: 25%;
    *margin-left: 24.969%;
  }
  .pure-offset-xl-7-24 {
    margin-left: 29.1667%;
    *margin-left: 29.1357%;
  }
  .pure-offset-xl-1-3,
  .pure-offset-xl-8-24 {
    margin-left: 33.3333%;
    *margin-left: 33.3023%;
  }
  .pure-offset-xl-3-8,
  .pure-offset-xl-9-24 {
    margin-left: 37.5%;
    *margin-left: 37.469%;
  }
  .pure-offset-xl-2-5 {
    margin-left: 40%;
    *margin-left: 39.969%;
  }
  .pure-offset-xl-5-12,
  .pure-offset-xl-10-24 {
    margin-left: 41.6667%;
    *margin-left: 41.6357%;
  }
  .pure-offset-xl-11-24 {
    margin-left: 45.8333%;
    *margin-left: 45.8023%;
  }
  .pure-offset-xl-1-2,
  .pure-offset-xl-12-24 {
    margin-left: 50%;
    *margin-left: 49.969%;
  }
  .pure-offset-xl-13-24 {
    margin-left: 54.1667%;
    *margin-left: 54.1357%;
  }
  .pure-offset-xl-7-12,
  .pure-offset-xl-14-24 {
    margin-left: 58.3333%;
    *margin-left: 58.3023%;
  }
  .pure-offset-xl-3-5 {
    margin-left: 60%;
    *margin-left: 59.969%;
  }
  .pure-offset-xl-5-8,
  .pure-offset-xl-15-24 {
    margin-left: 62.5%;
    *margin-left: 62.469%;
  }
  .pure-offset-xl-2-3,
  .pure-offset-xl-16-24 {
    margin-left: 66.6667%;
    *margin-left: 66.6357%;
  }
  .pure-offset-xl-17-24 {
    margin-left: 70.8333%;
    *margin-left: 70.8023%;
  }
  .pure-offset-xl-3-4,
  .pure-offset-xl-18-24 {
    margin-left: 75%;
    *margin-left: 74.969%;
  }
  .pure-offset-xl-19-24 {
    margin-left: 79.1667%;
    *margin-left: 79.1357%;
  }
  .pure-offset-xl-4-5 {
    margin-left: 80%;
    *margin-left: 79.969%;
  }
  .pure-offset-xl-5-6,
  .pure-offset-xl-20-24 {
    margin-left: 83.3333%;
    *margin-left: 83.3023%;
  }
  .pure-offset-xl-7-8,
  .pure-offset-xl-21-24 {
    margin-left: 87.5%;
    *margin-left: 87.469%;
  }
  .pure-offset-xl-11-12,
  .pure-offset-xl-22-24 {
    margin-left: 91.6667%;
    *margin-left: 91.6357%;
  }
  .pure-offset-xl-23-24 {
    margin-left: 95.8333%;
    *margin-left: 95.8023%;
  }
  .pure-offset-xl-1,
  .pure-offset-xl-1-1,
  .pure-offset-xl-5-5,
  .pure-offset-xl-24-24 {
    margin-left: 100%;
  }
}
.pure-g [class*=pure-u] {
  font-family: Arboria, Arial, sans-serif;
}

[data-flex] {
  display: flex;
}

[data-flex~=row] {
  flex-direction: row;
}

[data-flex~=row-reverse] {
  flex-direction: row-reverse;
}

[data-flex~=column] {
  flex-direction: column;
}

[data-flex~=column-reverse] {
  flex-direction: column-reverse;
}

[data-flex~=wrap] {
  flex-wrap: wrap;
}

[data-flex~=nowrap] {
  flex-wrap: nowrap;
}

[data-flex~=c-start] {
  align-content: flex-start;
}

[data-flex~=c-end] {
  align-content: flex-end;
}

[data-flex~=c-center] {
  align-content: center;
}

[data-flex~=c-stretch] {
  align-content: stretch;
}

[data-flex~=c-between] {
  align-content: space-between;
}

[data-flex~=c-around] {
  align-content: space-around;
}

[data-flex~=m-start] {
  justify-content: flex-start;
}

[data-flex~=m-end] {
  justify-content: flex-end;
}

[data-flex~=m-center] {
  justify-content: center;
}

[data-flex~=m-around] {
  justify-content: space-around;
}

[data-flex~=m-between] {
  justify-content: space-between;
}

[data-flex~=x-start] {
  align-items: flex-start;
}

[data-flex~=x-end] {
  align-items: flex-end;
}

[data-flex~=x-center] {
  align-items: center;
}

[data-flex~=x-stretch] {
  align-items: stretch;
}

[data-flex-child~=x-baseline] {
  align-self: baseline;
}

[data-flex-child~=x-start] {
  align-self: flex-start;
}

[data-flex-child~=x-end] {
  align-self: flex-end;
}

[data-flex-child~=x-center] {
  align-self: center;
}

[data-flex-child~=x-stretch] {
  align-self: stretch;
}

[data-flex-child~=grow] {
  flex-grow: 1;
}

[data-flex-child~=nogrow] {
  flex-basis: 0;
}

[data-flex-child~=shrink] {
  flex-shrink: 1;
}

[data-flex-child~=noshrink] {
  flex-shrink: 0;
}

@media screen and (min-width: 35.5em) {
  [data-flex~=sm-row] {
    flex-direction: row;
  }
  [data-flex~=sm-row-reverse] {
    flex-direction: row-reverse;
  }
  [data-flex~=sm-column] {
    flex-direction: column;
  }
  [data-flex~=sm-column-reverse] {
    flex-direction: column-reverse;
  }
  [data-flex~=sm-wrap] {
    flex-wrap: wrap;
  }
  [data-flex~=sm-nowrap] {
    flex-wrap: nowrap;
  }
  [data-flex~=c-sm-start] {
    align-content: flex-start;
  }
  [data-flex~=c-sm-end] {
    align-content: flex-end;
  }
  [data-flex~=c-sm-center] {
    align-content: center;
  }
  [data-flex~=c-sm-stretch] {
    align-content: stretch;
  }
  [data-flex~=c-sm-between] {
    align-content: space-between;
  }
  [data-flex~=c-sm-around] {
    align-content: space-around;
  }
  [data-flex~=m-sm-start] {
    justify-content: flex-start;
  }
  [data-flex~=m-sm-end] {
    justify-content: flex-end;
  }
  [data-flex~=m-sm-center] {
    justify-content: center;
  }
  [data-flex~=m-sm-around] {
    justify-content: space-around;
  }
  [data-flex~=m-sm-between] {
    justify-content: space-between;
  }
  [data-flex~=x-sm-start] {
    align-items: flex-start;
  }
  [data-flex~=x-sm-end] {
    align-items: flex-end;
  }
  [data-flex~=x-sm-center] {
    align-items: center;
  }
  [data-flex~=x-sm-stretch] {
    align-items: stretch;
  }
  [data-flex-child~=x-sm-baseline] {
    align-self: baseline;
  }
  [data-flex-child~=x-sm-start] {
    align-self: flex-start;
  }
  [data-flex-child~=x-sm-end] {
    align-self: flex-end;
  }
  [data-flex-child~=x-sm-center] {
    align-self: center;
  }
  [data-flex-child~=x-sm-stretch] {
    align-self: stretch;
  }
  [data-flex-child~=sm-grow] {
    flex-grow: 1;
  }
  [data-flex-child~=sm-nogrow] {
    flex-basis: 0;
  }
  [data-flex-child~=sm-shrink] {
    flex-shrink: 1;
  }
  [data-flex-child~=sm-noshrink] {
    flex-shrink: 0;
  }
}
@media screen and (min-width: 48em) {
  [data-flex~=md-row] {
    flex-direction: row;
  }
  [data-flex~=md-row-reverse] {
    flex-direction: row-reverse;
  }
  [data-flex~=md-column] {
    flex-direction: column;
  }
  [data-flex~=md-column-reverse] {
    flex-direction: column-reverse;
  }
  [data-flex~=md-wrap] {
    flex-wrap: wrap;
  }
  [data-flex~=md-nowrap] {
    flex-wrap: nowrap;
  }
  [data-flex~=c-md-start] {
    align-content: flex-start;
  }
  [data-flex~=c-md-end] {
    align-content: flex-end;
  }
  [data-flex~=c-md-center] {
    align-content: center;
  }
  [data-flex~=c-md-stretch] {
    align-content: stretch;
  }
  [data-flex~=c-md-between] {
    align-content: space-between;
  }
  [data-flex~=c-md-around] {
    align-content: space-around;
  }
  [data-flex~=m-md-start] {
    justify-content: flex-start;
  }
  [data-flex~=m-md-end] {
    justify-content: flex-end;
  }
  [data-flex~=m-md-center] {
    justify-content: center;
  }
  [data-flex~=m-md-around] {
    justify-content: space-around;
  }
  [data-flex~=m-md-between] {
    justify-content: space-between;
  }
  [data-flex~=x-md-start] {
    align-items: flex-start;
  }
  [data-flex~=x-md-end] {
    align-items: flex-end;
  }
  [data-flex~=x-md-center] {
    align-items: center;
  }
  [data-flex~=x-md-stretch] {
    align-items: stretch;
  }
  [data-flex-child~=x-md-baseline] {
    align-self: baseline;
  }
  [data-flex-child~=x-md-start] {
    align-self: flex-start;
  }
  [data-flex-child~=x-md-end] {
    align-self: flex-end;
  }
  [data-flex-child~=x-md-center] {
    align-self: center;
  }
  [data-flex-child~=x-md-stretch] {
    align-self: stretch;
  }
  [data-flex-child~=md-grow] {
    flex-grow: 1;
  }
  [data-flex-child~=md-nogrow] {
    flex-basis: 0;
  }
  [data-flex-child~=md-shrink] {
    flex-shrink: 1;
  }
  [data-flex-child~=md-noshrink] {
    flex-shrink: 0;
  }
}
@media screen and (min-width: 64em) {
  [data-flex~=lg-row] {
    flex-direction: row;
  }
  [data-flex~=lg-row-reverse] {
    flex-direction: row-reverse;
  }
  [data-flex~=lg-column] {
    flex-direction: column;
  }
  [data-flex~=lg-column-reverse] {
    flex-direction: column-reverse;
  }
  [data-flex~=lg-wrap] {
    flex-wrap: wrap;
  }
  [data-flex~=lg-nowrap] {
    flex-wrap: nowrap;
  }
  [data-flex~=c-lg-start] {
    align-content: flex-start;
  }
  [data-flex~=c-lg-end] {
    align-content: flex-end;
  }
  [data-flex~=c-lg-center] {
    align-content: center;
  }
  [data-flex~=c-lg-stretch] {
    align-content: stretch;
  }
  [data-flex~=c-lg-between] {
    align-content: space-between;
  }
  [data-flex~=c-lg-around] {
    align-content: space-around;
  }
  [data-flex~=m-lg-start] {
    justify-content: flex-start;
  }
  [data-flex~=m-lg-end] {
    justify-content: flex-end;
  }
  [data-flex~=m-lg-center] {
    justify-content: center;
  }
  [data-flex~=m-lg-around] {
    justify-content: space-around;
  }
  [data-flex~=m-lg-between] {
    justify-content: space-between;
  }
  [data-flex~=x-lg-start] {
    align-items: flex-start;
  }
  [data-flex~=x-lg-end] {
    align-items: flex-end;
  }
  [data-flex~=x-lg-center] {
    align-items: center;
  }
  [data-flex~=x-lg-stretch] {
    align-items: stretch;
  }
  [data-flex-child~=x-lg-baseline] {
    align-self: baseline;
  }
  [data-flex-child~=x-lg-start] {
    align-self: flex-start;
  }
  [data-flex-child~=x-lg-end] {
    align-self: flex-end;
  }
  [data-flex-child~=x-lg-center] {
    align-self: center;
  }
  [data-flex-child~=x-lg-stretch] {
    align-self: stretch;
  }
  [data-flex-child~=lg-grow] {
    flex-grow: 1;
  }
  [data-flex-child~=lg-nogrow] {
    flex-basis: 0;
  }
  [data-flex-child~=lg-shrink] {
    flex-shrink: 1;
  }
  [data-flex-child~=lg-noshrink] {
    flex-shrink: 0;
  }
}
@media screen and (min-width: 80em) {
  [data-flex~=xl-row] {
    flex-direction: row;
  }
  [data-flex~=xl-row-reverse] {
    flex-direction: row-reverse;
  }
  [data-flex~=xl-column] {
    flex-direction: column;
  }
  [data-flex~=xl-column-reverse] {
    flex-direction: column-reverse;
  }
  [data-flex~=xl-wrap] {
    flex-wrap: wrap;
  }
  [data-flex~=xl-nowrap] {
    flex-wrap: nowrap;
  }
  [data-flex~=c-xl-start] {
    align-content: flex-start;
  }
  [data-flex~=c-xl-end] {
    align-content: flex-end;
  }
  [data-flex~=c-xl-center] {
    align-content: center;
  }
  [data-flex~=c-xl-stretch] {
    align-content: stretch;
  }
  [data-flex~=c-xl-between] {
    align-content: space-between;
  }
  [data-flex~=c-xl-around] {
    align-content: space-around;
  }
  [data-flex~=m-xl-start] {
    justify-content: flex-start;
  }
  [data-flex~=m-xl-end] {
    justify-content: flex-end;
  }
  [data-flex~=m-xl-center] {
    justify-content: center;
  }
  [data-flex~=m-xl-around] {
    justify-content: space-around;
  }
  [data-flex~=m-xl-between] {
    justify-content: space-between;
  }
  [data-flex~=x-xl-start] {
    align-items: flex-start;
  }
  [data-flex~=x-xl-end] {
    align-items: flex-end;
  }
  [data-flex~=x-xl-center] {
    align-items: center;
  }
  [data-flex~=x-xl-stretch] {
    align-items: stretch;
  }
  [data-flex-child~=x-xl-baseline] {
    align-self: baseline;
  }
  [data-flex-child~=x-xl-start] {
    align-self: flex-start;
  }
  [data-flex-child~=x-xl-end] {
    align-self: flex-end;
  }
  [data-flex-child~=x-xl-center] {
    align-self: center;
  }
  [data-flex-child~=x-xl-stretch] {
    align-self: stretch;
  }
  [data-flex-child~=xl-grow] {
    flex-grow: 1;
  }
  [data-flex-child~=xl-nogrow] {
    flex-basis: 0;
  }
  [data-flex-child~=xl-shrink] {
    flex-shrink: 1;
  }
  [data-flex-child~=xl-noshrink] {
    flex-shrink: 0;
  }
}
* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

:root {
  --color-comms: #2174ab;
  --color-comms-light: #46a8d4;
  --color-energy: #dc1839;
  --color-energy-light: #f1425e;
  --color-primary: #000;
  --color-primary-light: #707070;
  --color-primary-highlight: rgba(0, 0, 0, 0.07);
  --color-primary-contrast: rgb(245, 245, 245);
  --color-primary-contrast-trans: rgba(245, 245, 245, 0.5);
  --color-secondary: var(--color-energy);
  --color-secondary-light: var(--color-energy-light);
  --color-secondary-contrast: #fff;
  --color-gray-darker: rgba(0, 0, 0, 0.8);
  --color-gray-dark: rgba(0, 0, 0, 0.32);
  --color-gray: rgba(0, 0, 0, 0.2);
  --color-gray-light: rgba(0, 0, 0, 0.12);
}

html, body, #root {
  height: 100%;
  min-height: 100%;
}

html {
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: var(--color-primary-contrast);
  color: var(--color-primary);
}
body:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  background-image: url("../images/bg-comms.svg"), url("../images/bg-energy.svg");
  background-size: 320px, 240px;
  background-position: left -120px top 40%, right -60px top 70%;
  transition: background-position 0.8s cubic-bezier(0.5, 0.1, 0.4, 1), filter 1s, opacity 1s;
  pointer-events: none;
}
@media screen and (min-width: 35.5em) {
  body:before {
    background-size: 50%, 35%;
    background-position: left -33% top 50%, right -15% top 52%;
  }
}
@media screen and (min-width: 64em) {
  body:before {
    background-size: 60%, 45%;
    background-position: left -50% top 50%, right -20% top 53%;
  }
}
@media screen and (min-width: 80em) {
  body:before {
    background-size: 850px, 700px;
    background-position: left -280px top 50%, right -180px top 55%;
  }
}
body.sector-energy:before, body.sector-communications:before {
  filter: blur(6px);
  opacity: 0.5;
}
body.sector-energy {
  --color-secondary: var(--color-energy);
  --color-secondary-light: var(--color-energy-light);
}
body.sector-energy:before {
  background-position: top 50% left -250%, 50%;
}
body.sector-communications {
  --color-secondary: var(--color-comms);
  --color-secondary-light: var(--color-comms-light);
}
body.sector-communications:before {
  background-position: 50%, top 50% right -250%;
}

#root {
  overflow-y: auto;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

hr {
  border: solid var(--color-primary);
  border-width: 1px 0 0;
  margin: 1.5rem 0;
}
hr.gray {
  border-color: var(--color-primary);
}

*[class*=pure-u] {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media screen and (min-width: 48em) {
  *[class*=pure-u] {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.container,
.container-fluid {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media screen and (min-width: 48em) {
  .container,
  .container-fluid {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.container > .pure-g,
.container-fluid > .pure-g {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
@media screen and (min-width: 48em) {
  .container > .pure-g,
  .container-fluid > .pure-g {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
}

.pure-g-wide {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
@media screen and (min-width: 48em) {
  .pure-g-wide {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 64em) {
  .container {
    max-width: 960px;
  }
}
@media screen and (min-width: 80em) {
  .container {
    max-width: 1140px;
  }
}

a, .pseudo-link {
  color: var(--color-secondary);
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
}
a:hover, .pseudo-link:hover {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

img {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
}

.upload-img {
  display: block;
  height: auto;
  margin: 3.75rem auto 1rem;
  width: 298px;
}
@media screen and (min-width: 48em) {
  .upload-img {
    margin-top: 7rem;
  }
}

.ReactModalPortal {
  position: relative;
  z-index: 999;
}
.ReactModalPortal .ReactModal__Overlay {
  overflow: auto;
}

.badge {
  background: var(--color-primary);
  border-radius: 2rem;
  color: var(--color-primary-contrast);
  display: inline-block;
  padding: 0 1rem;
}

pre {
  font-family: Arboria, Arial, sans-serif;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.placeholder-lozenge {
  background-color: gainsboro;
  border-radius: 0.5rem;
  height: 1rem;
}

@font-face {
  font-family: Arboria;
  src: local("Arboria Regular"), url("../fonts/arboria-medium.woff2") format("woff2"), url("../fonts/arboria-medium.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Arboria;
  src: local("Arboria Medium"), url("../fonts/arboria-medium.woff2") format("woff2"), url("../fonts/arboria-medium.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Arboria;
  src: local("Arboria Bold"), url("../fonts/arboria-bold.woff2") format("woff2"), url("../fonts/arboria-bold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 0;
}

h1 {
  font-family: Arboria, Arial, sans-serif;
  font-size: 2.75rem;
  line-height: 3.5rem;
  letter-spacing: 0;
}

h2 {
  font-family: Arboria, Arial, sans-serif;
  font-size: 2.25rem;
  line-height: 3rem;
  letter-spacing: 0;
}

h3 {
  font-family: Arboria, Arial, sans-serif;
  font-size: 1.75rem;
  line-height: 2.5rem;
  letter-spacing: 0;
}

h4 {
  font-family: Arboria, Arial, sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;
}

h5 {
  font-family: Arboria, Arial, sans-serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: 0;
}

h6 {
  font-family: Arboria, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
}

strong {
  font-weight: 500;
}

.color-primary {
  color: var(--color-primary);
}

.color-secondary {
  color: var(--color-secondary);
}

.color-gray-darker {
  color: var(--color-gray-darker);
}

body {
  font-family: Arboria, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  color: var(--color-primary);
}

.page-title {
  font-family: Arboria, Arial, sans-serif;
  font-size: 1.75rem;
  line-height: 2rem;
  letter-spacing: 0;
}
@media screen and (min-width: 48em) {
  .page-title {
    font-family: Arboria, Arial, sans-serif;
    font-size: 2.25rem;
    line-height: 3.5rem;
    letter-spacing: 0;
  }
}
@media screen and (min-width: 64em) {
  .page-title {
    font-family: Arboria, Arial, sans-serif;
    font-size: 2.75rem;
    line-height: 4rem;
    letter-spacing: 0;
  }
}

.case-reference-container {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
}

.case-reference {
  background-color: transparent;
  margin: 0 0.6rem 0 0;
  font-family: Arboria, Arial, sans-serif;
  font-size: 0.75rem;
  line-height: 1.6rem;
  letter-spacing: 0;
}
@media screen and (min-width: 64em) {
  .case-reference {
    font-family: Arboria, Arial, sans-serif;
    font-size: 0.875rem;
    line-height: 2rem;
    letter-spacing: 0;
  }
}

.copy-link, .check-link {
  border: 1px solid var(--color-primary);
  border-radius: 1.25rem;
  box-sizing: border-box;
  transition: all 0.3s ease;
  width: 1.3rem;
  height: 1.3rem;
  line-height: 1.3rem;
  padding: 1px;
}
@media screen and (min-width: 64em) {
  .copy-link, .check-link {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    padding: 0;
  }
}

.copy-link:before {
  background: url("../images/icons/copy-default.svg") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  height: 15px;
  width: 12px;
}
@media screen and (min-width: 64em) {
  .copy-link:before {
    width: 14px;
  }
}
.copy-link:hover:before {
  background: url("../images/icons/copy-hover.svg") no-repeat 50% 50%;
  content: "";
}

.check-link:before {
  background: url("../images/icons/check-default.svg") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  height: 15px;
  width: 12px;
}
@media screen and (min-width: 64em) {
  .check-link:before {
    width: 14px;
  }
}

.form-group {
  margin-bottom: 1.5rem;
  position: relative;
}
.form-group.required label:after {
  font-weight: 400;
  content: " *";
  color: #c80a0a;
}
.form-group.required label.pure-radio:after {
  content: none;
}
.form-group label {
  color: var(--color-primary);
  display: block;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.form-group[class*=font-sm] label {
  font-weight: 400;
}
.form-group.invalid .form-control {
  border-color: #c80a0a;
}
.form-group.invalid .charCount {
  color: #c80a0a;
}
.form-group .pure-form-message {
  font-size: 0.875rem;
}
.form-group .form-control {
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  display: block;
  font-family: Arboria, Arial, sans-serif;
  padding: 0 1rem;
  transition: all 0.3s ease;
  width: 100%;
}
.form-group .form-control:focus, .form-group .form-control:hover {
  border-color: var(--color-secondary);
}
.form-group input.form-control {
  height: 3rem;
}
.form-group input.disabled {
  cursor: not-allowed;
}
.form-group textarea.form-control {
  min-height: 12rem;
  overflow: auto;
  padding: 1rem;
  resize: none;
}
@media screen and (min-width: 64em) {
  .form-group textarea.form-control.lg-tall {
    min-height: 24rem;
  }
}
.form-group.checkbox label {
  cursor: pointer;
  margin-bottom: 0;
  min-height: 1.5rem;
  padding-left: 2rem;
  position: relative;
}
.form-group.checkbox label span:before {
  background: #fff;
  color: #000;
  border: 1px solid var(--color-gray-dark);
  border-radius: 2px;
  box-sizing: border-box;
  content: "";
  display: inline-block;
  height: 1.5rem;
  left: 0;
  position: absolute;
  top: 0;
  transition: border 0.3s ease;
  width: 1.5rem;
}
.form-group.checkbox label.disabled span {
  color: var(--color-gray-light);
  cursor: not-allowed;
}
.form-group.checkbox label.disabled span:before {
  border: 1px solid var(--color-gray-light);
}
.form-group.checkbox label.disabled span:hover:before {
  border-color: var(--color-gray-light);
}
.form-group.checkbox label:hover span:before, .form-group.checkbox label.checked span:before {
  border-color: var(--color-secondary);
}
.form-group.checkbox label.checked span:before {
  background: #ffffff url("../images/icons/checkbox-tick.svg") no-repeat 50% 50%;
  background-size: 60%;
}
.form-group.checkbox label.checked input.consumer-details-checkbox + span:before {
  box-shadow: 0 0 3px 2px var(--color-secondary);
}
.form-group.checkbox label input {
  position: absolute;
  opacity: 0;
}
.form-group.checkbox label input:not(.consumer-details-checkbox):focus + span:before {
  box-shadow: 0 0 3px 2px var(--color-secondary);
}
.form-group .field-messages {
  background: #c80a0a;
  color: #fff;
  border-radius: 4px;
  display: inline-block;
  margin-top: 8px;
  position: relative;
}
.form-group .field-messages:before {
  border: 8px solid transparent;
  border-bottom-color: #c80a0a;
  border-top: none;
  content: "";
  display: block;
  height: 0;
  left: 7px;
  position: absolute;
  top: -6px;
  width: 1px;
}
.form-group .field-messages .field-message-error {
  color: #fff;
  display: block;
  font-weight: 500;
  font-size: 1rem;
  margin: 0.25rem 0.5rem;
}
.form-group.error-on-absolute .field-messages {
  position: absolute;
  z-index: 1;
}
.form-group .radio-options .radio-buttons-label {
  font-size: 1.2rem;
}
.form-group .radio-options .pure-radio {
  font-weight: normal;
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
}
.form-group .radio-options .pure-radio input {
  width: 1.2rem;
  height: 1.2rem;
  flex-shrink: 0;
  translate: 0 0.2rem;
}

.pure-radio input {
  accent-color: var(--color-secondary);
}

.checkbox-wrap {
  align-items: center;
  background: #fff;
  border-radius: 4px;
  display: flex;
  height: 3rem;
  overflow: hidden;
  padding: 0 0.75rem;
  text-overflow: ellipsis;
}
.checkbox-wrap .form-group {
  margin-bottom: 0;
}

.pure-form-aligned .form-group {
  margin-bottom: 1rem;
}

input.toggle {
  appearance: none;
  width: 40px;
  height: 20px;
  border-radius: 20px;
  background-color: #ccc;
  position: relative;
  transition: background-color 0.2s;
  cursor: pointer;
}
input.toggle:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  transform-origin: center;
  scale: 0.9;
  transition: transform 0.2s;
}
input.toggle:checked {
  background-color: #26a100;
}
input.toggle:checked:before {
  transform: translateX(22px);
}

.charCount {
  position: absolute;
  right: 0;
  font-size: 0.875rem;
  color: var(--color-gray-darker);
}
.charCount.invalid {
  color: #c80a0a;
}

.error-message-colour-override {
  color: #c80a0a;
}

.navbar-toggle {
  padding: 0;
}
.navbar-toggle .icon-bar {
  background: var(--color-primary);
  border-radius: 3px;
  height: 4px;
  width: 28px;
}
.navbar-toggle .icon-bar + .navbar-toggle .icon-bar {
  margin-top: 5px;
}

.table-wrapper {
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  overflow: hidden;
}

.paddedCell {
  padding: 0 0.75rem;
}

.table-stripped .table-row:nth-child(2n+1) {
  background: var(--color-gray-light);
}

.table-header {
  background-color: var(--color-primary-highlight);
}

.table-row {
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid var(--color-primary-highlight);
}
.table-row + .table-row {
  border-top: 0;
}
.table-row .pure-g[class*=pure-u] {
  padding-left: 0;
  padding-right: 0;
}
.table-row.hoverable {
  transition: background 0.2s ease;
}
.table-row.hoverable:hover {
  background-color: var(--color-gray-light);
  cursor: pointer;
}
.table-row .divided {
  border-left: 1px solid var(--color-primary);
}
.table-row .form-group {
  margin-bottom: 0;
}

.divided-list {
  border-bottom: 1px solid var(--color-primary);
  list-style: none;
  margin: 0;
  padding: 0;
}
.divided-list li {
  border-top: 1px solid var(--color-primary);
  padding: 0.5rem;
  transition: background 0.2s ease;
}
.divided-list li:hover {
  background: var(--color-gray-light);
  cursor: pointer;
}

ul {
  list-style-image: url("../images/icons/ul-bullet.svg");
}

.MuiTooltip-popper ul {
  list-style-image: initial;
  padding-left: 1rem;
}

.card hr {
  border-color: var(--color-primary);
  display: none;
}
.card .has-shadow {
  box-shadow: none;
}
@media screen and (min-width: 48em) {
  .card {
    background-color: var(--color-primary-contrast);
    border: 1px solid var(--color-primary);
    border-radius: 0.5rem;
  }
  .card .has-shadow {
    box-shadow: 0 0 10px 0 var(--color-shadow, rgba(0, 0, 0, 0.2));
  }
  .card .card-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 44rem;
    padding: 0 1.5rem;
  }
  .card .card-content.card-content-sm {
    max-width: 35rem;
  }
  .card hr {
    display: block;
    margin: 3rem 0;
  }
}
